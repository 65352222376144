import { IAppSettingsRepository, IOrdersStore } from '@application/ports'
import { LangOptions, TranslationsObject, TranslationLanguages } from '@application/types'

export interface ISetLocaleUseCase {
  execute: (locale: LangOptions) => void
}

export class SetLocaleUseCase implements ISetLocaleUseCase {
  private repository: IAppSettingsRepository
  private store: IOrdersStore
  private translator: TranslationsObject<unknown, TranslationLanguages>

  public constructor(
    repository: IAppSettingsRepository,
    store: IOrdersStore,
    translator: TranslationsObject<unknown, TranslationLanguages>
  ) {
    this.repository = repository
    this.store = store
    this.translator = translator
  }

  public execute(locale: LangOptions): void {
    this.repository.setLocale(locale)
    this.store.setLocale(locale)
    this.translator.setOptions({
      language: locale,
      fallback: 'uk'
    })
  }
}
