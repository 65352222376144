export enum HttpCodes {
    OK = 200,
    BadRequest = 400,
    Unauthorized = 401,
    PaymentRequired = 402,
    Forbidden = 403,
    NotFound = 404,
    MethodNotAllowed = 405,
    NotAcceptable = 406,
    ProxyAuthenticationRequired = 407,
    RequestTimeout = 408,
    Conflict = 409,
    Gone = 410,
    PreconditionFailed = 412,
    TooManyRequests = 429,
    InternalServerError = 500,
    NotImplemented = 501,
    BadGateway = 502,
    ServiceUnavailable = 503,
    GatewayTimeout = 504
}

export type ErrorResponse = {
    code: HttpCodes
    body?: unknown
};

export class HttpError {
    public code: HttpCodes

    constructor(resp: ErrorResponse) {
        this.code = resp.code
    }
}

export type TransformResponse<T> = (response: Response) => Promise<T>

export type OkResult<OkType> = {
    readonly _tag: 'Ok'
    readonly value: OkType
};

export type ErrResult<ErrType> = {
    readonly _tag: 'Err'
    readonly value: ErrType
};

export type Result<OkType, ErrType> = OkResult<OkType> | ErrResult<ErrType>
