import { Storage, StorageFabric } from '@tools/storage'
import { IAppSettingsRepository } from '@application/ports'
import { LangOptions } from '@application/types'

import { localeDecoder } from './decoders'

export class AppSettingsRepository implements IAppSettingsRepository {
  private storage: Storage

  public constructor(storage: Storage) {
    this.storage = storage
  }

  public getLocale(): LangOptions {
    return this.storage.get<LangOptions>('locale', localeDecoder) ?? 'uk'
  }

  public setLocale(locale: LangOptions): void {
    this.storage.save('locale', locale)
  }

}

export const appSettings = new AppSettingsRepository(StorageFabric.getLocalStorage())
