import { decoder } from '@tools/http'
import { orderCategoryDecoder } from '@repositories/categories/decoders'
import { OrderDTO } from '@domain/order'
import { CategoryResearchingDTO } from "@domain/category-researching";

export const orderDecoder = decoder.object<OrderDTO>((field) => ({
  id: field('id', decoder.number),
  name: field('name', decoder.string),
  dateStart: field('dateStart', decoder.string),
  dateEnd: field('dateEnd', decoder.string),
  area: field('area', decoder.array(decoder.array(decoder.number))),
  status: field('status', decoder.oneOf('in process', 'finished')),
  category: field('category', orderCategoryDecoder),
  researchingList: field('researchingList',
    decoder.array(
      decoder.object<CategoryResearchingDTO>((field) => ({
        id: field('id', decoder.number),
        name: field('name', decoder.string),
      }))
    )
  )
}))

export const ordersDecoder = decoder.array(orderDecoder)
