import { format, Locale } from 'date-fns'
import add from 'date-fns/add'
import { enUS, uk } from 'date-fns/locale'
import { appSettings } from '@repositories/app-settings'
import { LangOptions } from '@application/types'

const locales: { [locale in LangOptions]: Locale } = { en: enUS, uk }

export function getCurrentYear() {
  return new Date().getFullYear()
}

export function getCurrentMonth() {
  return new Date().getMonth()
}

export function getCurrentDate() {
  return new Date().getDate()
}

export function addDays(date: Date, days: number) {
  return add(date, { days })
}

export function formatDate(date: string) {
  return format(new Date(date), 'dd MMM, HH:mm',{ locale: getLocale() })
}

function getLocale(): Locale {
  const lang: LangOptions = appSettings.getLocale() as LangOptions || 'uk'
  return locales[lang]
}
