import { MouseEventHandler } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'

type IconProps = {
  iconName: string
  className?: string
  position?: 'left' | 'right'
  onClick?: MouseEventHandler<SVGSVGElement>
}

export const Icon = ({ iconName, className, position, onClick }: IconProps) => {
  return (
    <svg
      aria-hidden="true"
      className={classNames(styles.container, className, {
        [styles.left]: position === 'left',
        [styles.right]: position === 'right',
      })}
      onClick={onClick}
    >
      <use xlinkHref={`#icon-${iconName}`} />
    </svg>
  )
}
