import { Link } from 'react-router-dom'
import classNames from 'classnames'
import translate from '@tools/translation'
import { formatDate } from '@tools/date'
import { Order } from '@domain/order'
import { Icon } from '@components/icon'

import styles from './index.module.scss'

type OrdersItemProps = React.ComponentProps<'button'> & {
  order: Order
}

function OrdersItem({ children, order, ...props }: OrdersItemProps) {
  const translations = translate.use().ordersList

  return (
    <Link to={`/${order.isFinished ? 'order-result' : 'order-process'}/${order.id}`}>
      <div className={styles.container}>
        <div className={styles.left}>
          <Icon iconName={order.iconName} />
          <div className={styles.leftTextWrap}>
            <p className={styles.leftText}>{order.name}</p>
            {order.isFinished ? (
              <p className={styles.leftDate}>{formatDate(order.startDate)} - {formatDate(order.endDate)}</p>
            ) : (
              <p className={styles.leftDate}>{translations.to} {formatDate(order.endDate)}</p>
            )}
          </div>
        </div>
        <button
          type="button"
          className={classNames(styles.button, {
            [styles.process]: order.isInProcess,
            [styles.done]: order.isFinished,
          })}
          {...props}
        >
          {order.isFinished ?  translations.done : translations.process}
        </button>
      </div>
    </Link>
  )
}

export default OrdersItem
