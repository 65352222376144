import { ICreateOrderStore } from '@application/ports'
import { ISetStepUseCase } from '@application/use-cases/set-step'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface ISelectTimeUseCase {
  execute: (time: string) => void
}

export class SelectTimeUseCase implements ISelectTimeUseCase {
  private store: ICreateOrderStore
  private setStepUseCase: ISetStepUseCase

  public constructor(store: ICreateOrderStore, setStepUseCase: ISetStepUseCase) {
    this.store = store
    this.setStepUseCase = setStepUseCase
  }

  public execute(time: string): void {
    this.store.setSelectedTime(time)
    this.setStepUseCase.execute(CREATE_ORDER_STEPS.PREVIEW)
  }
}
