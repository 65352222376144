import { useCallback } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import translate from '@tools/translation'
import { OrderCategory } from '@domain/order-category'
import { CategoryResearching } from '@domain/category-researching'
import Button from '@components/button'
import { InfoDetails } from '@components/info-details'

import { ResearchingListStepUIState } from './_ui-state'
import { ResearchingListItem } from './researching-list-item'
import styles from './index.module.scss'

type ResearchingListStepProps = {
  selectedCategory: OrderCategory
  selectedList: CategoryResearching[]
  onSelectResearchingList: (researchingList: CategoryResearching[]) => void
}

export const ResearchingListStep = observer((props: ResearchingListStepProps) => {
  const translations = translate.use().createOrder
  const categoriesTranslations: StringTranslations = translate.use().categories
  const uiState = useLocalObservable(
    () => new ResearchingListStepUIState(props.selectedCategory, props.selectedList)
  )

  const renderItem = useCallback((researching: CategoryResearching) => {
    const checked = uiState.isChecked(researching)
    return (
      <ResearchingListItem
        key={researching.id}
        className={styles.itemChecked}
        text={categoriesTranslations[researching.name]}
        checked={checked}
        onChange={() => uiState.toggleSelectedResearching(researching)}
      />
    )
  }, [])

  return (
    <div className={styles.container}>
      <InfoDetails
        iconName={props.selectedCategory.iconName}
        title={translations.categorySelected}
        text={categoriesTranslations[props.selectedCategory.name]}
        className={styles.item}
      />
      <div className={styles.itemCheckedWrap}>
        {props.selectedCategory.researchingList.map(renderItem)}
      </div>
      <div className={styles.btnWrap}>
        <Button
          disabled={!uiState.isResearchingListSelected}
          className={styles.btn}
          variant="primary"
          onClick={() => props.onSelectResearchingList(uiState.selectedList)}
        >
          {translations.nextBtn}
        </Button>
      </div>
    </div>
  )
})
