import { ICreateOrderStore, INavigator, IOrdersRepository } from '../ports'
import { ordersListLocation } from '../locations'

export interface ICreateOrderUseCase {
  execute: () => Promise<void>
}

export class CreateOrderUseCase  implements ICreateOrderUseCase {
  private repository: IOrdersRepository
  private store: ICreateOrderStore
  private navigator: INavigator

  public constructor(repository: IOrdersRepository, store: ICreateOrderStore, navigator: INavigator) {
    this.repository = repository
    this.store = store
    this.navigator = navigator
  }

  public async execute() {
    if (this.store.isOrderCreatingAllowed) {
      const payload = this.store.orderPayload
      await this.repository.save(payload)
      this.navigator.navigate(`${ordersListLocation}?order-created=1`)
    }
  }
}
