import { createTranslations } from 'react-ridge-translations'
import { appSettings } from '@repositories/app-settings'
import { TranslationLanguages } from '@application/types'

import splash from './pages/splash'
import ordersList from './pages/ordersList'
import createOrder from './pages/createOrder'
import orderProcess from './pages/orderProcess'
import orderCompleted from './pages/orderCompleted'
import categories from './components/categories'

const deviceLanguage = appSettings.getLocale()

const fallback = 'uk'

// create a translation object with your translations
const translate = createTranslations<TranslationLanguages>()(
  {
    ordersList,
    createOrder,
    splash,
    orderProcess,
    orderCompleted,
    categories,
  },
  {
    language: deviceLanguage,
    fallback: fallback,
  },
)
export default translate
