import { Http } from './http.interface'
import { FetchHttpService } from './fetch/fetch-http.service'
import { Expect } from './expect'
import { DecodeMethods } from '../decoder'
import { ResultMethods } from './result'

export const decoder = DecodeMethods.getInstance()
export const result = ResultMethods.getInstance()
export const expect = Expect.getInstance()

export class HttpFabric {
    static fetchHttp: FetchHttpService

    static getFetchHttp(): Http {
        if (!this.fetchHttp) this.fetchHttp = new FetchHttpService(expect, result)
        return this.fetchHttp
    }
}

export * from './helpers/types'
export * from './http.interface'
