import { useState } from 'react'
import { Decoder } from '@tools/decoder'
import { StorageFabric } from '@tools/storage'

export function useLocalStorage<T>(
  key: string,
  decode: Decoder<T>,
  initialValue: T,
): [T, (value: T) => void] {
  const storage = StorageFabric.getLocalStorage()
  const [storedValue, setStoredValue] = useState(() => {
    try {
      return storage.get<T>(key, decode) || initialValue
    } catch (error) {
      return initialValue
    }
  })

  const setValue = (value: T) => {
    try {
      setStoredValue(value)
      storage.save<T>(key, value)
    } catch (error) {
      console.log(error)
    }
  }

  return [storedValue, setValue]
}
