export class NoSelectedCategoryError extends Error {

  constructor() {
    super();
    Object.setPrototypeOf(this, NoSelectedCategoryError.prototype);
  }

  public get message(): string {
    return 'No selected category during order creating'
  }

}

export class OrderNotFound extends Error {

  constructor() {
    super();
    Object.setPrototypeOf(this, NoSelectedCategoryError.prototype);
  }

  public get message(): string {
    return 'Order not found in the storage'
  }

}
