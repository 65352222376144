import { useMemo } from 'react'
import classNames from 'classnames'
import translate from '@tools/translation'
import { formatDate } from '@tools/date'
import { GeoMapboxService } from '@tools/map'
import { IOrderResultsStore, IGeoService } from '@application/ports'
import { RESEARCHING_TYPES } from '@application/types'
import { useCreateInstance } from '@application/hooks/create-instance'
import { Icon } from '@components/icon'
import { InfoDetails } from '@components/info-details'

import { IOrderResultsController } from './_types'
import { OrderResultsHeader } from './header'
import { FieldMeasurementsResult } from './each-result/field-measurements-result'
import { FieldRoadsResult } from './each-result/field-roads-result'
import { SoilErosionResult } from './each-result/soil-erosion-result'
import { ChemicalParametersResult } from './each-result/chemical-parameters-result'
import { ReliefResult } from './each-result/3D-result'
import { FiresResult } from './each-result/fires-result'
import { RailwaysResult } from './each-result/railways-result'
import styles from './order-results.module.scss'

export const OrderResults = ({ store, controller }: ViewProps<IOrderResultsController, IOrderResultsStore>) => {
  const { order, selectedResearching } = store
  const translations = translate.use().orderCompleted
  const categoriesTranslations: StringTranslations = translate.use().categories
  const geo = useCreateInstance<IGeoService>(() => new GeoMapboxService())
  const mapUrl = useMemo(
    () => order ?
      geo.createUrlOfMap({ points: order.area, height: 144, width: window.innerWidth - 40 }) :
      '',
    [order]
  )
  const closeResearching = controller.showResearchingResult.undo.bind(controller.showResearchingResult)

  const ResearchingResult = useMemo(() => {
    switch (selectedResearching?.id) {
      case RESEARCHING_TYPES.FIELD_MEASUREMENTS: return FieldMeasurementsResult
      case RESEARCHING_TYPES.FIELD_ROADS: return FieldRoadsResult
      case RESEARCHING_TYPES.SOIL_EROSION: return SoilErosionResult
      case RESEARCHING_TYPES.CHEMICAL_PARAMETERS: return ChemicalParametersResult
      case RESEARCHING_TYPES.RELIEF_RESULTS: return ReliefResult
      case RESEARCHING_TYPES.FIRES: return FiresResult
      case RESEARCHING_TYPES.RAILWAYS: return RailwaysResult
      default: return FieldMeasurementsResult
    }
  }, [selectedResearching?.id])

  return (
    <div className={styles.container}>
      {!!selectedResearching && (<ResearchingResult researching={selectedResearching} onClose={closeResearching} />)}
      <OrderResultsHeader name={order?.name ?? ''} />
      {order && (
        <>
          <div className={styles.top}>
            <InfoDetails
              iconName={order.iconName}
              title={translations.resultCategory}
              text={categoriesTranslations[order.category.name]}
              className={styles.category}
            />
            <InfoDetails
              className={styles.time}
              iconName="clock"
              title={translations.resultTime}
              text={`${formatDate(order.startDate)} - ${formatDate(order.endDate)}`}
            />

            <div className={styles.resultWrap}>
              {order.researchingList.map((researching, index) => (
                <div
                  key={researching.id}
                  className={classNames(styles.result, {
                    [styles.isLast]: index === order?.researchingList.length - 1
                  })}
                  onClick={() => controller.showResearchingResult.execute(researching)}
                >
                  <img
                    className={styles.resultImg}
                    src={researching.imagePreview}
                    alt="preview map"
                  />
                  <div className={styles.resultText}>
                    <h3>{categoriesTranslations[researching.name]}</h3>
                    <Icon iconName="arrow-tailed-right" />
                  </div>
                </div>
              ))}
            </div>

            {!!order.video && (
              <a href={order.video.url} className={styles.resultWrap} target="_blank">
                <div className={styles.result}>
                  <img
                    className={styles.resultImg}
                    src={order.video.preview}
                    alt="preview video"
                  />
                  <div className={styles.resultText}>
                    <h3>{translations.resultVideo}</h3>
                    <Icon iconName="play-circle" />
                  </div>
                </div>
              </a>
            )}

            <div className={styles.map}>
              <h4>{translations.titleArea}</h4>
              <div className={styles.mapImg}>
                <img src={mapUrl} alt="Map" />
              </div>
            </div>
          </div>

          <div className={styles.footer} onClick={controller.repeatOrder.execute.bind(controller.repeatOrder)}>
            <Icon iconName="arrow-round" />
            <h3>{translations.resultRepeat}</h3>
          </div>
          {!!navigator.share && (
            <div className={styles.footer} onClick={() => order && controller.shareOrder.execute({ text: order.toString() })}>
              <Icon iconName="share" />
              <h3>{translations.resultShare}</h3>
            </div>
          )}
        </>
      )}
    </div>
  )
}
