import { CategoryResearching } from '@domain/category-researching'
import { ICreateOrderStore } from '@application/ports'
import { ISetStepUseCase } from '@application/use-cases/set-step'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface ISelectResearchingListUseCase {
  execute: (researchingList: CategoryResearching[]) => void
}

export class SelectResearchingListUseCase implements ISelectResearchingListUseCase {
  private store: ICreateOrderStore
  private setStepUseCase: ISetStepUseCase

  public constructor(store: ICreateOrderStore, setStepUseCase: ISetStepUseCase) {
    this.store = store
    this.setStepUseCase = setStepUseCase
  }

  public execute(researchingList: CategoryResearching[]): void {
    this.store.setSelectedResearchingList(researchingList)
    this.setStepUseCase.execute(CREATE_ORDER_STEPS.MAP)
  }
}
