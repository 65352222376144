import { INavigator, IOrderResultsStore } from '@application/ports'

export interface IRepeatOrderUseCase {
  execute: () => void
}

export class RepeatOrderUseCase implements IRepeatOrderUseCase {
  private navigator: INavigator
  private store: IOrderResultsStore

  constructor(store: IOrderResultsStore, navigator: INavigator) {
    this.store = store
    this.navigator = navigator
  }

  public execute() {
    const order = this.store.order
    if (order) {
      this.navigator.navigate(`/create-order?${order.toQueryString()}`)
    }
  }

}
