import { observer, useLocalObservable } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { StorageFabric } from '@tools/storage'
import { OrdersRepository } from '@repositories/orders'
import { useCreateInstance } from '@application/hooks/create-instance'

import { LoadOrderUseCase } from '@application/use-cases/load-order'
import { SubscribeOrderStatusUseCase } from '@application/use-cases/subscribe-order-status'

import { useOrderProcessController } from './_controller'
import { OrderProcessStore } from './_store'
import { OrderProcess } from './order-process.component'

const View = observer(OrderProcess)

export const OrderProcessPage = () => {
  const navigate = useNavigate()
  const store = useLocalObservable(() => new OrderProcessStore())
  const repository = useCreateInstance(() => new OrdersRepository(StorageFabric.getLocalStorage()))
  const controller = useOrderProcessController({
    useCases: {
      loadOrder: useCreateInstance(() => new LoadOrderUseCase(repository, store)),
      subscribeOrderStatus: useCreateInstance(() => new SubscribeOrderStatusUseCase(store, repository, { navigate })),
    }
  })

  return <View controller={controller} store={store} />
}
