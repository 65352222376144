import classNames from 'classnames'
import { Icon } from '@components/icon'

import styles from './index.module.scss'

type LinkProps = {
  iconName: string
  text: string
  disabled?: boolean
  className?: string
  onClick: () => void
}

export const Link = ({ className, iconName, disabled, text, onClick }: LinkProps) => {
  return (
    <div
      onClick={disabled ? undefined : onClick}
      className={classNames(className, styles.container, {
        [styles.isDisabled]: disabled,
      })}
    >
      <div className={styles.text}>
        <Icon iconName={iconName} className={classNames(styles.icon)} />
        <h3>{text}</h3>
      </div>
      <Icon className={styles.icon} iconName="arrow-right" />
    </div>
  )
}
