import { LocalStorageService } from './local-storage.service'
import { Storage } from './storage.interface'

export class StorageFabric {
    static localeStorage: LocalStorageService

    static getLocalStorage(): Storage {
        if (!this.localeStorage) this.localeStorage = new LocalStorageService()
        return this.localeStorage
    }
}

export * from './storage.interface'
