import { ICreateOrderStore } from '@application/ports'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface ISetStepUseCase {
  execute: (step: CREATE_ORDER_STEPS) => void
  undo: () => void
}

export class SetStepUseCase implements ISetStepUseCase {
  private store: ICreateOrderStore
  private activeSteps: CREATE_ORDER_STEPS[] = [CREATE_ORDER_STEPS.CATEGORY]

  public constructor(store: ICreateOrderStore) {
    this.store = store
  }

  public execute(step: CREATE_ORDER_STEPS): void {
    this.activeSteps.push(step)
    this.store.setStep(step)
  }

  public undo(): void {
    this.activeSteps.pop()
    let prevStep = this.activeSteps[this.activeSteps.length - 1]
    if (prevStep === CREATE_ORDER_STEPS.REQUESTS) {
      this.activeSteps.pop()
      prevStep = this.activeSteps[this.activeSteps.length - 1]
    }
    if (prevStep) {
      return this.store.setStep(prevStep)
    }
  }
}
