import { ICategoriesRepository } from '@application/ports'
import { OrderCategory } from '@domain/order-category'

import { orderCategories } from '../../_mocks/categories'

export class CategoriesRepository implements ICategoriesRepository {

  public async getAll(): Promise<OrderCategory[]> {
    return orderCategories.map((orderDTO) => new OrderCategory(orderDTO))
  }

}
