import { FC, ChangeEvent } from 'react'
import classNames from 'classnames'
import { Icon } from '@components/icon'

import styles from './index.module.scss'

export type CheckboxProps = {
  checked: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  id?: string
  className?: string
  variant?: 'round'
  name?: string
}

export const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  variant,
  onChange,
  name,
  id
}) => {
  return (
    <label
      htmlFor={id}
      className={classNames(styles.container, className)}
      onClick={(event) => event.stopPropagation()}
    >
      <input
        id={id}
        type="checkbox"
        className={styles.checkbox}
        checked={checked}
        onChange={onChange}
        name={name}
      />

      <span
        className={classNames(styles.label, {
          [styles.round]: variant === 'round',
        })}
      >
        <span className={styles.icon}>
          <Icon iconName="check" className={styles.iconCheck} />
        </span>
      </span>
    </label>
  )
}
