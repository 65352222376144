import { IAppSettingsRepository, IOrdersStore } from '@application/ports'

export interface IGetLocaleUseCase {
  execute: () => void
}

export class GetLocaleUseCase implements IGetLocaleUseCase {
  private repository: IAppSettingsRepository
  private store: IOrdersStore

  public constructor(repository: IAppSettingsRepository, store: IOrdersStore) {
    this.repository = repository
    this.store = store
  }

  public execute(): void {
    const locale = this.repository.getLocale()
    this.store.setLocale(locale)
  }
}
