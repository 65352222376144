import { Storage } from '@tools/storage'
import { addDays } from '@tools/date'
import { OrderPayload } from '@domain/order-payload'
import { Order, OrderDTO } from '@domain/order'
import { IOrdersRepository } from '@application/ports'
import { OrderNotFound } from '@application/errors'

import { ordersDecoder } from './decoders'

export class OrdersRepository implements IOrdersRepository {
  private storage: Storage

  public constructor(storage: Storage) {
    this.storage = storage
  }

  public async getAll(): Promise<Order[]> {
    const ordersDTO = this.getAllDTO()
    return ordersDTO.map((orderDTO) => new Order(orderDTO))
  }

  public async getById(id: number): Promise<Order> {
    const ordersDTO = this.getAllDTO()
    const orderDTO = ordersDTO.find((item) => item.id === id)
    if (!orderDTO) throw new OrderNotFound()
    return new Order(orderDTO)
  }

  public async save(order: OrderPayload): Promise<void> {
    const dto: OrderDTO = {
      id: Math.floor(Math.random() * 100),
      status: 'in process',
      dateEnd: addDays(new Date(), 3).toISOString(),
      dateStart: order.time,
      area: order.area,
      researchingList: order.researchingList.map(item => ({
        id: item.id,
        name: item.name
      })),
      category: {
        id: order.category.id,
        name: order.category.name,
        available: order.category.available,
      },
      name: this.generateOrderName(order.category.name)
    }
    const ordersDTO = this.getAllDTO()
    this.storage.save<OrderDTO[]>('orders', [...ordersDTO, dto])
  }

  public async update(order: Order): Promise<void> {
    const dto: OrderDTO = {
      id: order.id,
      status: order.isFinished ? 'finished' : 'in process',
      dateEnd: order.endDate,
      dateStart: order.startDate,
      area: order.area,
      researchingList: order.researchingList.map(item => ({
        id: item.id,
        name: item.name
      })),
      category: {
        id: order.category.id,
        name: order.category.name,
        available: order.category.available,
      },
      name: order.name
    }
    const ordersDTO = this.getAllDTO()
    this.storage.save<OrderDTO[]>('orders', ordersDTO.map(item => item.id === dto.id ? dto : item))
  }

  private getAllDTO(): OrderDTO[] {
    return this.storage.get<OrderDTO[]>('orders', ordersDecoder) || []
  }

  private generateOrderName(categoryName: string) {
    return `${categoryName.split(' ').map(word => word[0].toUpperCase()).join('')} ${this.getRandomNumber(100, 999)}-${this.getRandomNumber(1000, 9999)}`
  }

  private getRandomNumber(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min)
  }

}
