const userAgent = window.navigator.userAgent.toLowerCase()
const isSafari = /safari/.test(userAgent)
const isIosUserAgent = /iphone|ipod|ipad/.test(userAgent)

export enum PLATFORMS {
  IOS = 'iOS',
  ANDROID = 'Android',
  WEB = 'Web',
}

export const currentPlatform =
  isIosUserAgent && !isSafari
    ? PLATFORMS.IOS
    : userAgent.includes('wv')
    ? PLATFORMS.ANDROID
    : PLATFORMS.WEB

export const isIphoneSafari = !!navigator.userAgent.match(/iPhone/i)
export const isIos = currentPlatform === PLATFORMS.IOS
export const isAndroid = currentPlatform === PLATFORMS.ANDROID
export const isWeb = currentPlatform === PLATFORMS.WEB
