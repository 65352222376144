import translate from '@tools/translation'
import { LangOptions } from '@application/types'

import styles from './index.module.scss'

type OrdersListHeaderProps = {
  selectedLang: LangOptions
  selectLang: (lang: LangOptions) => void
}

export const OrdersListHeader = ({ selectedLang, selectLang }: OrdersListHeaderProps) => {
  const translations = translate.use().ordersList

  return (
    <div className={styles.header}>
      <h1>{translations.title}</h1>
      <select
        name="language"
        className={styles.headerSelect}
        value={selectedLang}
        onChange={(event) => selectLang(event.target.value as LangOptions)}
      >
        <option value="en">Eng</option>
        <option value="uk">Укр</option>
      </select>
    </div>
  )
}
