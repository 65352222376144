import { Navigate, Route, Routes } from 'react-router-dom'
import { decoder } from '@tools/http'
import { useLocalStorage } from '@application/hooks/local-storage'
import {
  ordersListLocation,
  createOrderLocation,
  orderCompletedLocation,
  orderProcessLocation
} from '@application/locations'

import { CreateOrderPage } from './create-order'
import { OrderResultsPage } from './order-results'
import { OrderProcessPage } from './order-process'
import { OrdersListPage } from './orders-list'
import { Splash } from './splash'

export const AppRoutes = () => {

  const [isSplashSkipped, setSplashStatus] = useLocalStorage<boolean>('is-splash-skipped', decoder.boolean, false)

  if (!isSplashSkipped) return <Splash onNextPage={() => setSplashStatus(true)} />

  return (
    <Routes>
      <Route path="*" element={<Navigate to={ordersListLocation} />} />
      <Route path={ordersListLocation} element={<OrdersListPage />} />
      <Route path={createOrderLocation} element={<CreateOrderPage />} />
      <Route path={orderProcessLocation} element={<OrderProcessPage />} />
      <Route path={orderCompletedLocation} element={<OrderResultsPage />} />
    </Routes>
  )
}
