import translate from '@tools/translation'
import Button from '@components/button'
import { Icon } from '@components/icon'
import logoUrl from '@assets/logo-lg.svg'
import bgUrl from '@assets/splash-bg.png'

import styles from './index.module.scss'

type SplashProps = {
  onNextPage: () => void
}

export const Splash = (props: SplashProps) => {
  const translations = translate.use().splash

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${bgUrl})` }}>
      <img src={logoUrl} alt="logo" />
      <Button variant="secondary" onClick={props.onNextPage} className={styles.btn}>
        {translations.button}
        <Icon iconName="arrow-tailed-right" />
      </Button>
    </div>
  )
}
