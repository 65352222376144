import { GeoJSONSourceRaw } from 'mapbox-gl'
import { Feature, Geometry, Polygon } from "geojson";
import { IGeoService } from '@application/ports'
import { mapConfig } from '@application/configs'
import { MapData } from '@application/types'

import { convertPointsToStr, createCirclePoints } from './helpers'

export class GeoMapboxService implements IGeoService {

  public removeDuplicatePolygonVertexes(polygon: Point[]): Point[] {
    const polygonCopy: Point[] = []
    const lastPoint = polygon[polygon.length - 1]
    polygon.forEach((point) => {
      if (!polygonCopy.find((_point) => _point[0] === point[0] && _point[1] === point[1])) {
        polygonCopy.push(point)
      }
    })
    return polygonCopy.concat([lastPoint])
  }

  public isValidPolygon(polygon: Point[]): boolean {
    return polygon.length > 3
  }

  public createUrlOfMap({ points, width, height }: MapData): string {
    const polygonUrl = this.createUrlOfPolygon(points)
    width = width > 1280 ? 1280 : width
    height = height > 1280 ? 1280 : height
    return `https://api.mapbox.com/styles/v1/mapbox/outdoors-v11/static/${polygonUrl}/auto/${width}x${height}?padding=20&&access_token=${mapConfig.token}`
  }

  public createUrlOfPolygon(points: Point[]): string {
    const coords = convertPointsToStr(points)
    return encodeURIComponent(`geojson({"type":"Feature","properties":{}, "geometry":{"type":"Polygon","coordinates": [${coords}]}})`)
  }

  public isGeometryPolygon(geometry: Geometry): geometry is Polygon {
    return geometry.type === 'Polygon'
  }

  public createPolygonFeature(coords: Point[]): Feature {
    return {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates: [coords]
      }
    }
  }

  public createCircleSource(center: Point, radiusInKm: number): GeoJSONSourceRaw {
    const points = createCirclePoints({ center: center, R: radiusInKm })
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [this.createPolygonFeature(points)]
      }
    }
  }

  public createPolygonSource(coords: Point[]): GeoJSONSourceRaw {
    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [this.createPolygonFeature(coords)]
      }
    }
  }


}
