import translate from '@tools/translation'

import { ResearchingResultProps } from '../../_types'
import { ResearchResults } from '../../researching-result'
import styles from '../result.module.scss'

export const FieldRoadsResult = (props: ResearchingResultProps) => {
  const translations = translate.use().orderCompleted
  return (
    <ResearchResults image={props.researching.imagePreview} onClose={props.onClose}>
      <div className={styles.title}>{translations.resultDesignTitle}</div>

      <div className={styles.text}>{translations.resultDesignP1}</div>

      <div className={styles.text}>{translations.resultDesignP2}</div>

      <div className={styles.map}>
        <img src={props.researching.imagePreview} alt="map" />
      </div>

      <div className={styles.text}>{translations.resultDesignP3}</div>
    </ResearchResults>
  )
}
