import { useNavigate } from 'react-router-dom'
import { observer, useLocalObservable } from 'mobx-react-lite'
import { StorageFabric } from '@tools/storage'
import { OrdersRepository } from '@repositories/orders'
import { CategoriesRepository } from '@repositories/categories'
import { useCreateInstance } from '@application/hooks/create-instance'

import { LoadCategoriesUseCase } from '@application/use-cases/load-categories'
import { SetStepUseCase } from '@application/use-cases/set-step'
import { SelectCategoryUseCase } from '@application/use-cases/select-category'
import { SelectResearchingListUseCase } from '@application/use-cases/select-researching-list'
import { SelectAreaUseCase } from '@application/use-cases/select-area'
import { SelectTimeUseCase } from '@application/use-cases/select-time'
import { CreateOrderUseCase } from '@application/use-cases/create-order'
import { PreCreateOrderUseCase } from '@application/use-cases/pre-create-order'

import { useCreateOrderController } from './_controller'
import { CreateOrderStore } from './_store'
import { CreateOrder } from './create-order.component'

const View = observer(CreateOrder)

export const CreateOrderPage = () => {
  const navigate = useNavigate()
  const store = useLocalObservable(() => new CreateOrderStore())
  const ordersRepository = useCreateInstance(() => new OrdersRepository(StorageFabric.getLocalStorage()))
  const categoriesRepository = useCreateInstance(() => new CategoriesRepository())
  const setStepUseCase = useCreateInstance(() => new SetStepUseCase(store))
  const controller = useCreateOrderController({
    useCases: {
      loadCategories: useCreateInstance(() => new LoadCategoriesUseCase(categoriesRepository, store)),
      setStep: setStepUseCase,
      createOrder: useCreateInstance(() => new CreateOrderUseCase(ordersRepository, store, { navigate })),
      preCreateOrder: useCreateInstance(() => new PreCreateOrderUseCase(store, setStepUseCase)),
      selectCategory: useCreateInstance(() => new SelectCategoryUseCase(store, setStepUseCase)),
      selectResearchingList: useCreateInstance(() => new SelectResearchingListUseCase(store, setStepUseCase)),
      selectArea: useCreateInstance(() => new SelectAreaUseCase(store, setStepUseCase)),
      selectTime: useCreateInstance(() => new SelectTimeUseCase(store, setStepUseCase)),
    }
  })

  return <View controller={controller} store={store} />
}
