import { Order } from '@domain/order'
import OrdersItem from '../item'

import styles from './index.module.scss'

type OrdersListProps = {
  orders: Order[]
}

export const OrdersList = ({ orders }: OrdersListProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.itemsWrap}>
        {orders.map((order) => (
          <OrdersItem order={order} key={order.id} />
        ))}
      </div>
    </div>
  )
}
