import { CategoryResearching, CategoryResearchingDTO } from '@domain/category-researching'

import { OrderCategory, OrderCategoryDTO } from './order-category'
import { Entity } from './_entity'
import { convertPointsToStr } from "@tools/map";

export type OrderStatus = 'in process' | 'finished'
export type OrderVideo = {
  url: string
  preview: string
} | undefined

export type OrderDTO = {
  id: ID
  status: OrderStatus
  researchingList: CategoryResearchingDTO[]
  category: OrderCategoryDTO
  dateStart: string
  dateEnd: string
  area: Point[]
  name: string
}

export class Order extends Entity<OrderDTO>{
  private readonly orderCategory: OrderCategory
  private readonly researching: CategoryResearching[]

  public constructor(data: OrderDTO) {
    super(data)
    this.orderCategory = new OrderCategory(data.category)
    this.researching = data.researchingList.map(item => new CategoryResearching(item))
  }

  public get name(): string {
    return this.data.name
  }

  public get area(): Point[] {
    return this.data.area
  }

  public get category(): OrderCategory {
    return this.orderCategory
  }

  public get researchingList(): CategoryResearching[] {
    return this.researching
  }

  public get startDate(): string {
    return this.data.dateStart
  }

  public get endDate(): string {
    return this.data.dateEnd
  }

  public get isInProcess(): boolean {
    return this.data.status === 'in process'
  }

  public get isFinished(): boolean {
    return this.data.status === 'finished'
  }

  public get iconName(): string {
    return this.category.iconName
  }

  public get video(): OrderVideo {
    if (this.category.id === 2 || this.category.id === 2) return undefined
    if (this.category.id === 1) {
      return {
        url: 'https://d17q6hytwo1g7u.cloudfront.net/video-fires.mp4',
        preview: new URL(`../_assets/fires-preview.png`, import.meta.url).href
      }
    }
    return {
      url: 'https://d17q6hytwo1g7u.cloudfront.net/video-railway.mp4',
      preview: new URL(`../_assets/video-preview.png`, import.meta.url).href
    }
  }

  public complete() {
    this.data.status = 'finished'
  }

  public toQueryString() {
    const researchingIds = this.researchingList.map(item => item.id).join(',')
    return `category=${this.category.id}&researching=${researchingIds}&area=${convertPointsToStr(this.area)}`
  }

  public toString() {
    return JSON.stringify(this.data)
  }

}
