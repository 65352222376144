import { OrderCategoryDTO } from '@domain/order-category'

export const orderCategories: OrderCategoryDTO[] = [
  {
    id: 1,
    name: 'Landscape fires',
    available: true,
    researchingList: [
      { id: 5, name: 'Monitoring of landscape fires' }
    ]
  },
  {
    id: 2,
    name: 'Land inventory',
    available: true,
    researchingList: [
      { id: 1, name: 'Field measurements' },
      { id: 2, name: 'Design of field roads' },
      { id: 3, name: 'Predicting soil erosion' },
      { id: 4, name: 'Determination of chemical parameters' }
    ]
  },
  {
    id: 3,
    name: 'Digital terrain models',
    available: true,
    researchingList: [
      { id: 6, name: '3D relief model' }
    ]
  },
  {
    id: 4,
    name: 'Railway monitoring',
    available: true,
    researchingList: [
      { id: 7, name: 'Railway monitoring' }
    ]
  },
  {
    id: 5,
    name: 'Emergencies',
    available: false,
  },
  {
    id: 6,
    name: 'Geodesy and cartography',
    available: false,
  },
  {
    id: 7,
    name: 'Mining',
    available: false,
  },
  {
    id: 8,
    name: 'Public order',
    available: false,
  },
  {
    id: 9,
    name: 'Border guard',
    available: false,
  },
  {
    id: 10,
    name: 'Agriculture',
    available: false,
  },
  {
    id: 11,
    name: 'Environmental monitoring',
    available: false,
  },
  {
    id: 12,
    name: 'Search and rescue operations',
    available: false,
  },
  {
    id: 13,
    name: 'Construction',
    available: false,
  },
  {
    id: 14,
    name: 'Energy',
    available: false,
  },
]
