import { ReactNode, useState, useRef, UIEvent } from 'react'
import classnames from 'classnames'
import { useSwipeable } from 'react-swipeable'
import { Drawer } from '@components/drawer'

import styles from './index.module.scss'

export type ResultDrawerProps = {
  children: ReactNode
}

export function ResearchingResultDrawer({ children }: ResultDrawerProps) {
  const scrollDrawerTop = useRef<number>(0)
  const [isFullDrawer, setDrawerStatus] = useState<boolean>(false)
  const [isDrawerContentScrollable, setDrawerContentScrollingStatus] = useState<boolean>(false)
  const handlers = useSwipeable({
    onSwipedUp: () => {
      setDrawerStatus(true)
      setDrawerContentScrollingStatus(true)
    },
    onSwipedDown: () => {
      if (scrollDrawerTop.current <= 0) {
        setDrawerStatus(false)
        setTimeout(() => setDrawerContentScrollingStatus(false), 500)
      }
    },
  });

  const onScroll = (event: UIEvent<HTMLDivElement>) => {
    // @ts-ignore
    const scrollTop = event.target.scrollTop
    if (scrollTop <= 0) return setTimeout(() => scrollDrawerTop.current = scrollTop, 500)
    scrollDrawerTop.current = scrollTop
  }

  return (
    <Drawer isOpened isNotFull={!isFullDrawer}>
      <div
        {...handlers}
        className={classnames(styles.content, {
          [styles.isNotScrollable]: !isDrawerContentScrollable
        })}
        onScroll={onScroll}
      >
        {children}
      </div>
    </Drawer>
  )
}
