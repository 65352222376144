import { OrderCategory } from '@domain/order-category'
import { ICreateOrderStore } from '@application/ports'
import { ISetStepUseCase } from '@application/use-cases/set-step'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface ISelectCategoryUseCase {
  execute: (category: OrderCategory) => void
}

export class SelectCategoryUseCase implements ISelectCategoryUseCase {
  private store: ICreateOrderStore
  private setStepUseCase: ISetStepUseCase

  public constructor(store: ICreateOrderStore, setStepUseCase: ISetStepUseCase) {
    this.store = store
    this.setStepUseCase = setStepUseCase
  }

  public execute(category: OrderCategory): void {
    this.store.setSelectedCategory(category)
    this.setStepUseCase.execute(CREATE_ORDER_STEPS.RESEARCH)
  }
}
