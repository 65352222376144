import translate from '@tools/translation'

import { ResearchingResultProps } from '../../_types'
import { ResearchResults } from '../../researching-result'
import resultStyles from '../result.module.scss'
import styles from './index.module.scss'

export const FieldMeasurementsResult = (props: ResearchingResultProps) => {
  const translations = translate.use().orderCompleted
  return (
    <ResearchResults image={props.researching.imagePreview} onClose={props.onClose}>
      <div className={resultStyles.title}>{translations.resultFieldsTitle}</div>

      <div className={resultStyles.text}>{translations.resultFieldsP1}</div>

      <div className={resultStyles.map}>
        <img src={props.researching.imagePreview} alt="map" />
      </div>

      <div className={resultStyles.text}>{translations.resultFieldsP2}</div>

      <div className={styles.itemLabel}>{translations.resultFieldTable}</div>

      <div className={styles.itemWrap}>
        <div className={styles.item}>
          Поле №278
          <div className={styles.itemCount}>
            <p>1,67</p>
            <span>з 134,40</span>
          </div>
        </div>
        <div className={styles.item}>
          Поле №364
          <div className={styles.itemCount}>
            <p>5,67</p>
            <span>з 85,50</span>
          </div>
        </div>
        <div className={styles.item}>
          Поле №308
          <div className={styles.itemCount}>
            <p>3,53</p>
            <span>з 75,13</span>
          </div>
        </div>
        <div className={styles.item}>
          <b className={styles.itemBold}>Всього</b>
          <div className={styles.itemCount}>
            <p>
              <b className={styles.itemBold}>10,87</b>
            </p>
            <span>
              <b className={styles.itemBold}>з 295,08</b>
            </span>
          </div>
        </div>
      </div>

      <div className={resultStyles.text}>{translations.resultFieldsP3}</div>
    </ResearchResults>
  )
}
