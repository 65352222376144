import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import translate from '@tools/translation'
import { IOrdersStore } from '@application/ports'
import { useSearchParam } from '@application/hooks/search-param'
import Button from '@components/button'
import { Icon } from '@components/icon'
import { Notification } from '@components/notification'
import droneUrl from '@assets/drone.png'
import bgUrl from '@assets/order-bg.png'

import { IOrdersController } from './_types'
import { OrdersListHeader } from './header'
import { OrdersList } from './list/index.component'
import styles from './orders.module.scss'

type OrdersListContainerProps = ViewProps<IOrdersController, IOrdersStore>

export const OrdersListContainer = ({ controller, store }: OrdersListContainerProps) => {
  const translations = translate.use().ordersList
  const [orderCreatedQS, setOrderCreatedQS] = useSearchParam({ name: 'order-created', defaultValue: '0' })
  const isOrderCreated = orderCreatedQS === '1'
  const isEmptyList = !store.orders.length

  useEffect(() => {
    if (isOrderCreated) {
      const timer: Timer = setTimeout(() => setOrderCreatedQS('0'), 3000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isOrderCreated])

  return (
    <div className={styles.container} style={{ backgroundImage: `url(${bgUrl})` }}>
      <OrdersListHeader
        selectedLang={store.locale}
        selectLang={controller.setLocale.execute.bind(controller.setLocale)}
      />

      <div className={styles.content}>
        <OrdersList orders={store.orders} />
        {isEmptyList && (
          <div className={styles.contentImage}>
            <img src={droneUrl} alt="drone" />
            <p>{translations.text}</p>
          </div>
        )}
        <div className={styles.contentFooter}>
          {isEmptyList && (
            <h3>
              {translations.textBtn}
              <Icon className={styles.textIcon} iconName="arrow-tailed-right" />
            </h3>
          )}
          <Link to="/create-order">
            <Button variant="round">
              <Icon className={styles.btnIcon} iconName="plus" />
            </Button>
          </Link>
        </div>
      </div>
      {isOrderCreated && (
        <Notification text={translations.notificationOrderCreated} type="success" />
      )}
    </div>
  )
}
