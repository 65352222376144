import { ICreateOrderStore } from '@application/ports'
import { ISetStepUseCase } from '@application/use-cases/set-step'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface ISelectAreaUseCase {
  execute: (area: Point[]) => void
}

export class SelectAreaUseCase implements ISelectAreaUseCase {
  private store: ICreateOrderStore
  private setStepUseCase: ISetStepUseCase

  public constructor(store: ICreateOrderStore, setStepUseCase: ISetStepUseCase) {
    this.store = store
    this.setStepUseCase = setStepUseCase
  }

  public execute(area: Point[]): void {
    this.store.setSelectedArea(area)
    this.setStepUseCase.execute(CREATE_ORDER_STEPS.REQUESTS)
  }
}
