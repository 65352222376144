import { useState, useCallback } from 'react'
import { useNavigate } from "react-router-dom";
import classnames from 'classnames'
import translate from '@tools/translation'
import { CREATE_ORDER_STEPS } from '@application/types'
import { capitalizeFirstLetter } from '@application/helpers'
import { ordersListLocation } from '@application/locations'
import { Icon } from '@components/icon'
import Button from '@components/button'
import { CONFIRM_BUTTON_TYPE, Popup } from '@components/popup'

import styles from './index.module.scss'

export type CreateOrderHeaderProps = {
  step: CREATE_ORDER_STEPS
  onPrevStep: () => void
}

export const CreateOrderHeader = (props: CreateOrderHeaderProps) => {
  const navigate = useNavigate()
  const translations: StringTranslations = translate.use().createOrder
  const [isCancelPopupShown, setCancelPopupStatus] = useState<boolean>(false)

  const showCancelPopup = useCallback(() => setCancelPopupStatus(true), [])
  const hideCancelPopup = useCallback(() => setCancelPopupStatus(false), [])

  const confirmButton = {
    type: CONFIRM_BUTTON_TYPE.DANGER,
    text: translations.cancelPopupConfirmsBtn,
    onClick: () => navigate(ordersListLocation)
  }

  return (
    <div className={classnames(styles.container, styles[props.step])}>
      <div className={styles.text}>
        {
          props.step !== CREATE_ORDER_STEPS.CATEGORY ?
            <span onClick={props.onPrevStep} className={styles.textBack}><Icon iconName="arrow-tailed-left" /></span> :
            <span className={styles.textBack} />
        }
        <p className={styles.textTitle}>{translations.headerTitle}</p>
        <Button variant="text" onClick={showCancelPopup} className={styles.textBtn}>
          {translations.cancelBtn}
        </Button>
      </div>
      <h2>{translations[`header${capitalizeFirstLetter(props.step)}`]}</h2>
      {isCancelPopupShown && (
        <Popup
          title={translations.cancelPopupTitle}
          subtitle={translations.cancelPopupSubTitle}
          confirmButton={confirmButton}
          cancelBtnText={translations.cancelPopupBtn}
          onClose={hideCancelPopup}
        />
      )}
    </div>
  )
}
