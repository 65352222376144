import { useMemo } from 'react'
import { observer } from 'mobx-react-lite'
import translate from '@tools/translation'
import { GeoMapboxService } from '@tools/map'
import { formatDate } from '@tools/date'
import { ICreateOrderStore, IGeoService } from '@application/ports'
import { useCreateInstance } from '@application/hooks/create-instance'
import Button from '@components/button'
import { InfoDetails } from '@components/info-details'

import styles from './index.module.scss'

type PreviewStepProps = {
  store: ICreateOrderStore
  onCreateOrder: () => void
}

export const PreviewStep = observer((props: PreviewStepProps) => {
  const { store } = props
  const translations = translate.use().createOrder
  const categoriesTranslations: StringTranslations = translate.use().categories
  const geo = useCreateInstance<IGeoService>(() => new GeoMapboxService())
  const mapUrl = useMemo(
    () => geo.createUrlOfMap({ points: store.selectedArea, height: 144, width: window.innerWidth - 40 }),
    [store.selectedArea]
  )

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <InfoDetails
          iconName={store.selectedCategory.iconName}
          title={translations.categorySelected}
          text={categoriesTranslations[store.selectedCategory.name]}
        />

        <div className={styles.content}>
          <h4 className={styles.contentTitle}>{translations.previewTitleResearch}</h4>
          {store.selectedResearchingList.map((researching) => (
            <p key={researching.id}>{categoriesTranslations[researching.name]}</p>
          ))}
        </div>

        <div className={styles.map}>
          <h4>{translations.previewTitleArea}</h4>
          <div className={styles.mapImg}>
            <img src={mapUrl} alt="Map" />
          </div>
        </div>

        <InfoDetails
          className={styles.item}
          title={translations.previewTitleFly}
          text={formatDate(store.selectedTime)}
          iconName="plane"
        />
        <InfoDetails
          className={styles.itemPrice}
          title={translations.previewTitlePrice}
          text={translations.previewDescriptionPrice}
          iconName="dollar"
        />
      </div>

      <Button
        disabled={!store.isOrderCreatingAllowed}
        className={styles.btn}
        variant="primary"
        onClick={props.onCreateOrder}
      >
        {translations.nextBtn}
      </Button>
    </div>
  )
})
