import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import translate from '@tools/translation'
import { formatDate } from '@tools/date'
import { GeoMapboxService } from '@tools/map'
import { IOrderProcessStore, IGeoService } from '@application/ports'
import { useCreateInstance } from '@application/hooks/create-instance'
import Button from '@components/button'
import { Icon } from '@components/icon'
import { InfoDetails } from '@components/info-details'
import flyUrl from '@assets/fly.png'

import { IOrderProcessController } from './_types'
import { OrderProcessVideo } from './video'
import styles from './order-process.module.scss'

export const OrderProcess = ({ store }: ViewProps<IOrderProcessController, IOrderProcessStore>) => {
  const translations = translate.use().orderProcess
  const geo = useCreateInstance<IGeoService>(() => new GeoMapboxService())
  const mapUrl = useMemo(
    () => store.order ?
      geo.createUrlOfMap({ points: store.order.area, height: window.innerHeight, width: window.innerWidth }) :
      '',
    [store.order]
  )

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <Link to="/orders-list">
          <Button variant="secondary" className={styles.topBtn}>
            <Icon className={styles.topIcon} iconName="arrow-tailed-left" />
          </Button>
        </Link>
      </div>

      {!!store.order && (
        <>
          <img className={styles.map} src={mapUrl} alt="Map" />
          {!!store.order.video && (<OrderProcessVideo videoUrl={store.order.video.url} />)}

          <div className={styles.planeImgWrap}>
            <img
              className={styles.planeImg}
              src={flyUrl}
              alt="plane flying"
            />
          </div>

          <InfoDetails
            title={translations.titleTime}
            text={formatDate(store.order.endDate)}
            iconName="plane-down"
          />
        </>
      )}
    </div>
  )
}
