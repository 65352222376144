import { action, makeObservable, observable } from 'mobx'
import { IOrdersStore } from '@application/ports'
import { LangOptions } from '@application/types'
import { Order } from '@domain/order'

export class OrdersStore implements IOrdersStore {
  @observable orders: Order[] = []
  @observable locale: LangOptions = 'uk'

  constructor() {
    makeObservable(this)
  }

  @action public setOrders(orders: Order[]) {
    this.orders = orders
  }

  @action public setLocale(locale: LangOptions) {
    this.locale = locale
  }
}
