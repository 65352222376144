import { useMemo, useEffect, useCallback } from 'react'
import { when } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react-lite'
import translate from '@tools/translation'
import { CategoryResearching } from '@domain/category-researching'
import { Loader } from '@components/loader'
import { InfoCard } from '@components/info-card'
import { Icon } from '@components/icon'

import { RequestStepUIState } from './_ui-state'
import styles from './index.module.scss'

type RequestStepProps = {
  selectedResearchingList: CategoryResearching[]
  onNextStep: () => void
}

export const RequestStep = observer((props: RequestStepProps) => {
  const translations: StringTranslations = translate.use().createOrder
  const infoCardsText = useMemo<string[]>(
    () => CategoryResearching.getTextsForInfoCard(props.selectedResearchingList),
    []
  )
  const uiState = useLocalObservable(() => new RequestStepUIState(
    [
      translations.stepCollection,
      translations.stepAnalysis,
      translations.stepSearch,
      translations.stepTime
    ],
    infoCardsText.length
  ))

  const waitForNextStep = useCallback(async () => {
    await when(() => uiState.isAllRequestsCompleted)
    props.onNextStep()
  }, [])

  useEffect(() => {
    waitForNextStep()
    return () => {
      uiState.onDestroy()
    }
  }, [])

  return (
    <div className={styles.container}>
      {uiState.requests.map((step) => (
        <div key={step} className={styles.text}>
          {
            uiState.finishedRequests.includes(step) ?
              (<span className={styles.finishedStep}><Icon iconName="check" /></span>) :
              <Loader />
          }
          <p>{step}</p>
        </div>
      ))}

      <InfoCard title={translations.infoTitle} text={translations[infoCardsText[uiState.indexCurrentInfoCard]]} icon="🧐" />
    </div>
  )
})

RequestStep.displayName = 'RequestStep'
