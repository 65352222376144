import { action, makeObservable, observable } from 'mobx'
import { Order } from '@domain/order'
import { IOrderResultsStore } from '@application/ports'
import { CategoryResearching } from '@domain/category-researching'

export class OrderResultsStore implements IOrderResultsStore {
  @observable order: Order | null = null
  @observable isLoading: boolean = true
  @observable selectedResearching: CategoryResearching | null = null

  constructor() {
    makeObservable(this)
  }

  @action setOrder(order: Order) {
    this.order = order
  }

  @action finishLoading() {
    this.isLoading = false
  }

  @action selectResearching(researching: CategoryResearching) {
    this.selectedResearching = researching
  }

  @action closeResearching() {
    this.selectedResearching = null
  }

}
