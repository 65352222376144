import { ISharingService } from "@application/ports";

export interface IShareOrderUseCase {
  execute: (data: ShareData) => Promise<void>
}

export class ShareOrderUseCase implements IShareOrderUseCase {
  private shareService: ISharingService

  constructor(shareService: ISharingService) {
    this.shareService = shareService
  }

  public async execute(data: ShareData) {
    await this.shareService.share(data)
  }

}
