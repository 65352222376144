import { action, makeObservable, observable, computed } from 'mobx'

const STEP_DELAY = 5000
const INFO_CARD_DELAY = 7000

export class RequestStepUIState {
  @observable finishedRequests: string[] = []
  @observable indexCurrentInfoCard: number = 0
  requests: string[] = []
  private cancelNextRequestTimer: Timer
  private setInfoCardTimer: Timer

  constructor(requests: string[], numberOfInfoCards: number) {
    makeObservable(this)
    this.requests = requests
    this.cancelNextRequestTimer = setInterval(() => this.addNextRequestToFinished(), STEP_DELAY)
    this.setInfoCardTimer = setInterval(
      () => this.setIndexOfCurrentInfoCard(numberOfInfoCards),
      INFO_CARD_DELAY
    )
  }

  get lastRequest(): string {
    return this.finishedRequests[this.finishedRequests.length - 1]
  }

  get nextRequest(): string {
    return this.requests[this.requests.findIndex((step) => step === this.lastRequest) + 1]
  }

  @computed get isAllRequestsCompleted(): boolean {
    return this.finishedRequests.length === this.requests.length
  }

  @action addNextRequestToFinished() {
    this.finishedRequests = this.finishedRequests.concat([this.nextRequest])
  }

  @action setIndexOfCurrentInfoCard(numberOfInfoCards: number) {
    this.indexCurrentInfoCard = this.indexCurrentInfoCard === numberOfInfoCards - 1 ?
      0 :
      this.indexCurrentInfoCard + 1
  }

  public onDestroy() {
    clearInterval(this.cancelNextRequestTimer)
    clearInterval(this.setInfoCardTimer)
  }

}
