import { IOrdersRepository, IOrdersStore } from '../ports'

export interface ILoadOrdersUseCase {
  execute: () => Promise<void>
}

export class LoadOrdersUseCase implements ILoadOrdersUseCase {
  private repository: IOrdersRepository
  private store: IOrdersStore

  public constructor(repository: IOrdersRepository, store: IOrdersStore) {
    this.repository = repository
    this.store = store
  }

  public async execute() {
    const orders = await this.repository.getAll()
    this.store.setOrders(orders)
  }
}
