import { IOrderProcessStore, INavigator, IOrdersRepository } from '../ports'

export interface ISubscribeOrderStatusUseCase {
  execute: (orderId: number) => Promise<void>
  stop: () => void
}

export class SubscribeOrderStatusUseCase implements ISubscribeOrderStatusUseCase {
  private navigator: INavigator
  private store: IOrderProcessStore
  private repository: IOrdersRepository
  private navigationTimer: Timer = 0

  public constructor(store: IOrderProcessStore, repository: IOrdersRepository, navigator: INavigator) {
    this.navigator = navigator
    this.store = store
    this.repository = repository
  }

  public async execute(orderId: number) {
    setTimeout(() => {
      this.store.completeOrder()
      const order = this.store.order
      if (order) {
        this.repository.update(order)
      }
    }, 15000)
    this.navigationTimer = setTimeout(
      () => this.navigator.navigate(`/order-result/${orderId}`),
      18000
    )
  }

  public stop() {
    clearTimeout(this.navigationTimer)
  }
}
