export type Circle = { center: Point, R: number }

export function convertPointsToStr(points: Point[]): string {
  let pointsStr = '['
  points.forEach((coords) => {
    pointsStr += '['
    coords.forEach((el, index) => {
      pointsStr += `${el},`
    })
    pointsStr = pointsStr.slice(0, -1);
    pointsStr += '],'
  })
  pointsStr = pointsStr.slice(0, -1);
  pointsStr += ']'
  return pointsStr
}

export function convertStrToPoints(str: string): Point[] {
  return str.slice(0, -2).substring(1).split('],').map(strPoint => {
    return strPoint.substring(1).split(',').map(item => +item)
  })
}

export function createCirclePoints(circle: Circle): Point[] {
  const ret = [], points = 128

  const distanceX = getDeltaLongitude(circle.R, circle.center[1])
  const distanceY = getDeltaLatitude(circle.R)

  let theta: number, x: number, y: number
  for (let i = 0; i < points; i++) {
    theta = (i / points) * (2 * Math.PI)
    x = distanceX * Math.sin(theta)
    y = distanceY * Math.cos(theta)

    ret.push([circle.center[0] + x, circle.center[1] + y])
  }
  ret.push(ret[0])
  return ret
}

export function isEveryPointInCircle(points: Point[], circle: Circle): boolean {
  return points.every(point => isPointInCircle(point, circle))
}

export function isPointInCircle(point: Point, circle: Circle): boolean {
  const distance = getDistanceFromLatLonInKm(circle.center, point)
  return distance < circle.R
}

export function degreesToRadians(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function getDistanceFromLatLonInKm(point1: Point, point2: Point): number {
  const R = 6371; // Radius of the Earth in km
  const dLat = degreesToRadians(point2[1] - point1[1]);
  const dLon = degreesToRadians(point2[0] - point1[0]);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(degreesToRadians(point1[1])) * Math.cos(degreesToRadians(point2[1])) *
    Math.sin(dLon / 2) * Math.sin(dLon / 2)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}

export function getBoundingBox(pLongitude: number, pLatitude: number, pDistanceInKm: number): [number, number, number, number] {
  const boundingBox = [];
  const deltaLat = getDeltaLatitude(pDistanceInKm);
  const deltaLong = getDeltaLongitude(pDistanceInKm, pLatitude);

  const minLat = pLatitude - deltaLat;
  const minLong = pLongitude - deltaLong;
  const maxLat = pLatitude + deltaLat;
  const maxLong = pLongitude + deltaLong;

  boundingBox[0] = minLong;
  boundingBox[1] = minLat;
  boundingBox[2] = maxLong;
  boundingBox[3] = maxLat;

  return boundingBox as [number, number, number, number];
}

export function convertBoundingBoxToPolygon(bbox: [number, number, number, number]): Point[] {
  return [[bbox[0], bbox[1]], [bbox[0], bbox[3]], [bbox[2], bbox[3]], [bbox[2], bbox[1]], [bbox[0], bbox[1]]]
}

export function getDeltaLatitude(distanceInKm: number) {
  return distanceInKm / 110.574235;
}

export function getDeltaLongitude(distanceInKm: number, pLatitude: number) {
  const longRadian = degreesToRadians(pLatitude);
  return distanceInKm / (110.572833 * Math.cos(longRadian));
}
