import { ICreateOrderStore } from '@application/ports'
import { CREATE_ORDER_STEPS } from '@application/types'

import { ICreateOrderController } from './_types'
import { CreateOrderHeader } from './header'
import { PreviewStep } from './preview-step'
import { CategoryStep } from './category-step'
import { ResearchingListStep } from './researching-list-step'
import { MapStep } from './map-step'
import { RequestStep } from './request-step'
import { TimeStep } from './time-step'
import styles from './create-order.module.scss'

export const CreateOrder = ({ store, controller }: ViewProps<ICreateOrderController, ICreateOrderStore>) => {

  const renderStep = () => {
    switch (store.step) {
      case CREATE_ORDER_STEPS.CATEGORY: return (
        <CategoryStep
          categories={store.categories}
          onSelectCategory={controller.selectCategory.execute.bind(controller.selectCategory)}
        />
      )
      case CREATE_ORDER_STEPS.RESEARCH: return (
        <ResearchingListStep
          selectedCategory={store.selectedCategory}
          selectedList={store.selectedResearchingList}
          onSelectResearchingList={controller.selectResearchingList.execute.bind(controller.selectResearchingList)}
        />
      )
      case CREATE_ORDER_STEPS.MAP: return (
        <MapStep
          selectedArea={store.selectedArea}
          onSelectArea={controller.selectArea.execute.bind(controller.selectArea)}
        />
      )
      case CREATE_ORDER_STEPS.REQUESTS: return (
        <RequestStep
          selectedResearchingList={store.selectedResearchingList}
          onNextStep={() => controller.setStep.execute(CREATE_ORDER_STEPS.TIME)}
        />
      )
      case CREATE_ORDER_STEPS.TIME: return (
        <TimeStep
          selectedTime={store.selectedTime}
          onSelectTime={controller.selectTime.execute.bind(controller.selectTime)}
        />
      )
      case CREATE_ORDER_STEPS.PREVIEW: return (
        <PreviewStep onCreateOrder={controller.createOrder.execute.bind(controller.createOrder)} store={store} />
      )
    }
  }

  return (
    <div className={styles.container}>
      <CreateOrderHeader step={store.step} onPrevStep={controller.setStep.undo.bind(controller.setStep)} />
      {renderStep()}
    </div>
  )
}
