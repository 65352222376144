import { useEffect, useCallback } from 'react'
import { convertStrToPoints } from '@tools/map'
import { useSearchParam } from '@application/hooks/search-param'

import { ICreateOrderController, CreateOrderControllerDeps } from './_types'

export const useCreateOrderController = ({ useCases }: CreateOrderControllerDeps): ICreateOrderController => {
  const [categoryId] = useSearchParam({ name: 'category', defaultValue: '' })
  const [researchingIdsStr] = useSearchParam({ name: 'researching', defaultValue: '' })
  const [area] = useSearchParam({ name: 'area', defaultValue: '' })
  const researchingIds: number[] = researchingIdsStr.split(',').map(item => +item)
  const points = convertStrToPoints(area)

  const setInitState = useCallback(async () => {
    await useCases.loadCategories.execute()
    if (categoryId && researchingIds.length && area) {
      useCases.preCreateOrder.execute(+categoryId, researchingIds, points)
    }
  }, [])

  useEffect(() => {
    setInitState()
  }, [])

  return {
    ...useCases
  }
}
