import { ICreateOrderStore } from '@application/ports'
import { ISetStepUseCase } from '@application/use-cases/set-step'
import { CREATE_ORDER_STEPS } from '@application/types'

export interface IPreCreateOrderUseCase {
  execute: (categoryId: number, researchingIds: number[], area: Point[]) => void
}

export class PreCreateOrderUseCase implements IPreCreateOrderUseCase {
  private store: ICreateOrderStore
  private setStepUseCase: ISetStepUseCase

  public constructor(store: ICreateOrderStore, setStepUseCase: ISetStepUseCase) {
    this.store = store
    this.setStepUseCase = setStepUseCase
  }

  public execute(categoryId: number, researchingIds: number[], area: Point[]): void {
    const categories = this.store.categories
    const category = categories.find(item => item.id === categoryId)
    if (!category) return
    const researchingList = category.researchingList.filter(item => researchingIds.includes(item.id))
    if (researchingList.length && area) {
      this.store.setSelectedCategory(category)
      this.store.setSelectedResearchingList(researchingList)
      this.store.setSelectedArea(area)
      this.setStepUseCase.execute(CREATE_ORDER_STEPS.REQUESTS)
    }
  }
}
