import { ReactNode } from 'react'
import classNames from 'classnames'

import styles from './index.module.scss'

export type DrawerProps = {
  isOpened: boolean
  children: ReactNode
  isNotFull?: boolean
  isAutoHeight?: boolean
  contentClassName?: string
}

export const Drawer = ({ isOpened, children, isNotFull, isAutoHeight, contentClassName }: DrawerProps) => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.isOpened]: isOpened,
        [styles.isNotFull]: isNotFull,
        [styles.isAutoHeight]: isAutoHeight,
      })}
    >
      {!isNotFull && (<div className={styles.overlay} />)}
      <div className={classNames(styles.content, contentClassName)}>
        {children}
      </div>
    </div>
  )
}
