import translate from '@tools/translation'
import { Icon } from '@components/icon'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

type OrderResultsHeaderProps = {
  name: string
}

export const OrderResultsHeader = (props: OrderResultsHeaderProps) => {
  const translations = translate.use().orderCompleted

  return (
    <div className={styles.container}>
      <Link to="orders-list">
        <Icon iconName="arrow-tailed-left" />
      </Link>
      <div className={styles.wrap}>
        <h3>{translations.resultHeader}</h3>
        <p>{props.name}</p>
      </div>
    </div>
  )
}
