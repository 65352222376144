import { Entity } from '@domain/_entity'

export type CategoryResearchingDTO = {
  id: ID
  name: string
}

export class CategoryResearching extends Entity<CategoryResearchingDTO> {

  public constructor(data: CategoryResearchingDTO) {
    super(data)
  }

  public get name(): string {
    return this.data.name
  }

  public get imagePreview(): string {
    switch (this.id) {
      case 1: return new URL(`../_assets/research-sm-1.png`, import.meta.url).href
      case 2: return new URL(`../_assets/research-sm-2.png`, import.meta.url).href
      case 3: return new URL(`../_assets/research-sm-1.png`, import.meta.url).href
      case 4: return new URL(`../_assets/research-sm-3.png`, import.meta.url).href
      case 5: return new URL(`../_assets/research-sm-4.png`, import.meta.url).href
      case 6: return new URL(`../_assets/research-sm-5.png`, import.meta.url).href
      case 7: return new URL(`../_assets/research-sm-6.png`, import.meta.url).href
      default: return new URL(`../_assets/research-sm-1.png`, import.meta.url).href
    }
  }

  public get textForInfoCard(): string {
    switch (this.id) {
      case 1: return 'exploreInfoCard'
      case 2: return 'exploreInfoCard'
      case 3: return 'erosionInfoCard'
      case 4: return 'chemicalInfoCard'
      case 5: return 'firesInfoCard'
      case 6: return '3DInfoCard'
      case 7: return 'railwayInfoCard'
      default: return 'exploreInfoCard'
    }
  }

  static getTextsForInfoCard(researchingList: CategoryResearching[]) {
    return [...new Set(researchingList.map(item => item.textForInfoCard))]
  }
}
