import successUrl from '@assets/success.svg'

import styles from './index.module.scss'

type NotificationProps = {
  text: string
  type: 'success' | 'error' | 'warn' | 'info'
}

export const Notification = ({ text }: NotificationProps) => {
  return (
    <div className={styles.container}>
      <img src={successUrl} alt="checkmark" />
      <p>{text}</p>
    </div>
  )
}
