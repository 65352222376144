import { ISharingService } from '@application/ports'

export class SharingService implements ISharingService {

  public async share(data: ShareData) {
    if ('share' in navigator) {
      await navigator.share(data)
    }
  }

}
