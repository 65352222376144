import { action, makeObservable, observable, computed } from 'mobx'
import { CategoryResearching } from '@domain/category-researching'
import { OrderCategory } from '@domain/order-category'

export class ResearchingListStepUIState {
  @observable selectedList: CategoryResearching[] = []

  constructor(category: OrderCategory, selectedList: CategoryResearching[]) {
    makeObservable(this)
    this.selectedList = selectedList
    if (category.researchingList.length === 1) {
      this.selectedList = category.researchingList
    }
  }

  @computed get isResearchingListSelected(): boolean {
    return !!this.selectedList.length
  }

  @action toggleSelectedResearching(researching: CategoryResearching) {
    if (this.isChecked(researching)) {
      this.removeSelectedResearching(researching.id)
    } else {
      this.addSelectedResearching(researching)
    }
  }

  public isChecked(researching: CategoryResearching): boolean {
    return !!this.selectedList.find((item) => item.id === researching.id)
  }

  private removeSelectedResearching(id: number) {
    this.selectedList = this.selectedList.filter((item) => item.id !== id)
  }

  private addSelectedResearching(researching: CategoryResearching) {
    this.selectedList = this.selectedList.concat([researching])
  }
}
