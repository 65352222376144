const createOrder = {
  nextBtn: {
    en: 'Next',
    uk: 'Далі',
  },
  headerTitle: {
    en: 'Create an order',
    uk: 'Створення замовлення',
  },
  headerCategory: {
    en: 'Choose category',
    uk: 'Оберіть категорію',
  },
  headerResearch: {
    en: 'Choose analysis type',
    uk: 'Оберіть дослідження',
  },
  headerMap: {
    en: 'Select the area',
    uk: 'Позначте область',
  },
  headerRequests: {
    en: 'Making requests',
    uk: 'Робимо запити',
  },
  headerTime: {
    en: 'When to the take off?',
    uk: 'Коли взлітаємо?',
  },
  headerPreview: {
    en: 'Check your order',
    uk: 'Перевірте замовлення',
  },
  categorySelected: {
    en: 'Category selected',
    uk: 'Обрана категорія',
  },
  infoTitle: {
    en: 'Did you know?',
    uk: 'Чи знаєте ви?',
  },
  exploreInfoCard: {
    en: 'An agronomist can explore/analyze an average of no more than 1,000 hectares of fields a week. Whereas a UAV can do this in one flight.',
    uk: 'За тиждень агроном може дослідити в середньому не більше 1000 га полів. Тоді як безпілотник це може зробити за один політ.',
  },
  erosionInfoCard: {
    en: 'In Ukraine, up to 600 million tons of soil are lost annually due to erosion. The use of UAVs allows you to detect erosion at an early stage',
    uk: 'В Україні щорічно від ерозії втрачається до 600 млн. т ґрунту. Використання БпЛА дозволяє виявити ерозії на ранній стадії',
  },
  chemicalInfoCard: {
    en: 'The use of UAVs saves up to 30% of fertilizers and pesticides, as well as 15% of seed',
    uk: 'Застосування БпЛА дозволяє заощаджувати до 30% добрив та пестицидів, а також 15 % насіннєвого матеріалу',
  },
  firesInfoCard: {
    en: 'In 2020, the cost of fires reached 633 million 776 thousand UAH. The use of UAVs quickly helps to establish the decisive direction of operational actions',
    uk: 'У 2020 році витрати від пожеж сягнули 633 млн 776 тис. грн. Використання БпЛА швидко допомагає встановити вирішальний напрямок оперативних дій',
  },
  ['3DInfoCard']: {
    en: 'The use of UAVs can achieve an accuracy of 1-2% compared to traditional mine surveying tools (8-12% error)',
    uk: 'Використання БПЛА дозволяє досягти точності до 1-2%, на відміну від маркшейдерських інструментів (8-12% похибки)',
  },
  railwayInfoCard: {
    en: 'The total fleet of freight cars is 83.5 thousand units. Of the available fleet of freight cars, the working fleet is 57.7 thousand units of freight cars',
    uk: 'Загальний парк вантажних вагонів становить 83,5 тис. одиниць. Із наявного парку вантажних вагонів робочий парк складає 57,7 тис. од. вагонів',
  },
  stepCollection: {
    en: 'Collecting data',
    uk: 'Проводимо збір даних',
  },
  stepAnalysis: {
    en: 'Analyzing data',
    uk: 'Аналізуємо дані',
  },
  stepSearch: {
    en: 'Looking for available UAV',
    uk: 'Шукаємо вільний БПЛА',
  },
  stepTime: {
    en: 'Looking for the nearest time avaiable ',
    uk: 'Шукаємо найближчий час',
  },
  avalibleTime: {
    en: 'The nearest time available',
    uk: 'Найближчий доступний час',
  },
  changeBtn: {
    en: 'Change',
    uk: 'Змінити',
  },
  changeTime: {
    en: 'Change time',
    uk: 'Змінити час',
  },
  previewTitleResearch: {
    en: 'Selected analysis',
    uk: 'Обрані дослідження',
  },
  previewTitleArea: {
    en: 'Area of analysis',
    uk: 'Область дослідження',
  },
  previewTitleFly: {
    en: 'Take-off time',
    uk: 'Час взльоту',
  },
  previewTitlePrice: {
    en: 'Price',
    uk: 'Вартість',
  },
  previewDescriptionPrice: {
    en: 'Free of charge',
    uk: 'Безкоштовно',
  },
  cancelBtn: {
    en: 'Cancel',
    uk: 'Скасувати',
  },
  cancelPopupTitle: {
    en: 'Cancel order',
    uk: 'Скасувати замовлення',
  },
  cancelPopupSubTitle: {
    en: 'Are you sure you want to cancel your order?',
    uk: 'Ви впевнені, що бажаєте скасувати замовлення?',
  },
  cancelPopupBtn: {
    en: 'No',
    uk: 'Ні',
  },
  cancelPopupConfirmsBtn: {
    en: 'Yes',
    uk: 'Так',
  },
  mapLimitedTitle: {
    en: 'Map is limited',
    uk: 'Мапа обмежена',
  },
  mapLimitedText: {
    en: 'The range of drones is 40 km from the station.',
    uk: 'Радіус дії дронів - 40 км від станції.',
  },
  mapUnableTitle: {
    en: 'Unable to mark',
    uk: 'Неможливо позначити',
  },
  mapUnableText: {
    en: 'You are out of range of drones',
    uk: 'Ви вийшли за межі радіусу дії дронів',
  },
}

export default createOrder
