import { useEffect } from 'react'
import { observer, useLocalObservable } from 'mobx-react-lite'
import translate from '@tools/translation'
import Button from '@components/button'
import { InfoCard } from '@components/info-card'
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import 'mapbox-gl/dist/mapbox-gl.css'

import { MAP_CONTAINER_ID, MapUIState } from './_ui-state'
import styles from './index.module.scss'

type MapStepProps = {
  selectedArea: Point[]
  onSelectArea: (area: Point[]) => void
}

export const MapStep = observer((props: MapStepProps) => {
  const translations = translate.use().createOrder
  const uiState = useLocalObservable(() => new MapUIState())

  useEffect(() => {
    uiState.init(props.selectedArea, translations)
    return () => {
      uiState.onDestroy()
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.map} id={MAP_CONTAINER_ID} />
      {uiState.notification && (
        <div className={styles.info}>
          <InfoCard
            title={uiState.notification.title}
            text={uiState.notification.text}
            icon={uiState.notification.icon}
            onClose={uiState.removeLastNotification.bind(uiState)}
          />
        </div>
      )}
      <div className={styles.btnWrap}>
        <Button
          disabled={!uiState.selectedArea.length}
          className={styles.btn}
          variant="primary"
          onClick={() => props.onSelectArea(uiState.selectedArea)}
        >
          {translations.nextBtn}
        </Button>
      </div>
    </div>
  )
})
