import { action, makeObservable, observable } from 'mobx'
import { IOrderProcessStore } from '@application/ports'
import { Order } from '@domain/order'

export class OrderProcessStore implements IOrderProcessStore {
  @observable order: Order | null = null
  @observable isLoading: boolean = true

  constructor() {
    makeObservable(this)
  }

  @action setOrder(order: Order) {
    this.order = order
  }

  @action completeOrder() {
    if (this.order) {
      this.order.complete()
    }
  }

  @action finishLoading() {
    this.isLoading = false
  }

}
