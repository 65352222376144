const orderCompleted = {
  resultHeader: {
    en: 'Results',
    uk: 'Результати',
  },
  resultCategory: {
    en: 'Category',
    uk: 'Категорія',
  },
  resultTime: {
    en: 'Analysis duration',
    uk: 'Час дослідження',
  },
  resultVideo: {
    en: 'Video record',
    uk: 'Відеозапис',
  },
  resultRepeat: {
    en: 'Repeat the order',
    uk: 'Повторити замовлення',
  },
  resultShare: {
    en: 'Share results',
    uk: 'Поділитись результатами',
  },
  titleArea: {
    en: 'Area of analysis',
    uk: 'Область дослідження',
  },
  resultFieldsTitle: {
    en: 'Field measurements',
    uk: 'Обміри полів'
  },
  resultFieldsP1: {
    en: 'As a result of one flight, 3 contours of the field were formed. On an area of 133 hectares, the result of each device ranged from ± 1.5 hectares. In some cases, this can be considered acceptable.',
    uk: 'В результаті одного об’їзду було сформовано 3 контури поля. На площі 133 га результат кожного приладу коливався в межах ± 1,5 га. В деяких випадках це можна вважати допустимим.',
  },
  resultFieldsP2: {
    en: 'The first two devices operate directly from the satellite signal, Tallysman GNSS receiver allows you to work in RTK (Real Time Kinematic) mode, receiving corrections from permanent stations.',
    uk: 'Перші два прилади працюють напряму від супутникового сигналу, GNSS приймач Tallysman дає можливість працювати в режимі RTK (Real Time Kinematic), отримуючи поправки від перманентних станцій.',
  },
  resultFieldsP3: {
    en: 'An operator flies the drone over an area of land, taking hundreds of pictures as it moves. Then, with the help of computer software, they stitch and layer the images, creating a model of the site. This process is also how drone photogrammetry works, and the end result is an accurate 3D representation of the area.',
    uk: 'На відкритій місцевості зміщення контурів було незначне, але під час обміру біля лісових насаджень відбувалися зміщення внаслідок послаблення сигналу. Найбільш точним та без зміщень виявився контур отриманий від приймача Tallysman.',
  },
  resultFieldTable: {
    en: 'Problem areas, hectares',
    uk: 'Проблемні площі посіву, га',
  },
  resultDesignTitle: {
    en: 'Design of field roads',
    uk: 'Проектування польових доріг'
  },
  resultDesignP1: {
    en: 'Road projects may seem deceptively simple to the outsider, but more work goes into the surveying process for road construction than the average driver imagines.',
    uk: 'За своїм призначенням внутрігосподарські шляхи поділяються на магістральні і польові.',
  },
  resultDesignP2: {
    en: "Accuracy is critical at every stage, from clearing the site to laying the first materials.",
    uk: "До магістральних належать шляхи, які зв'язують окремі населені пункти і виробничі центри між собою, з сівозмінними масивами, сільськогосподарськими угіддями та об'єктами із зберігання та пере­робки продукції. Магістральні шляхи проектуються шириною 6—8 м залежно від інтенсивності використання, характеру транспортних зв'язків і вантажообігу.",
  },
  resultDesignP3: {
    en: 'As drones have become the surveyor’s tool of choice on all kinds of worksites, it’s increasingly common to see drone surveys at work on paving jobs. But many paving contractors are still new to using drone technology to level up their operations.',
    uk: 'До польових належать шляхи, які обслуговують польові виробничі процеси і є продовженням мережі магістральних шляхів. Вони призначені для перевезення вантажів з полів і на поля, для переїздів працівників, для обслуговування працюючих на полях машинно- транспортних агрегатів, заправки машин паливно-мастильними матеріалами, а сівалок - насінням, для холостих заїздів. Ширина польових шляхів - 4-5 м.',
  },
  resultPredictingTitle: {
    en: 'Predicting soil erosion',
    uk: 'Прогнозування ерозії грунтів'
  },
  resultPredictingP1: {
    en: 'This is the classification of processes that involve grinding and polishing of surfaces by the act of removing integral portions of the material substrate. It can be termed as the wearing off of the high levels of a material\'s surface by the use of friction. It is a process used for trueing and leveling the substrate surface during the finishing process.',
    uk: 'Ерозія ґрунту є формою його деградації. Вона визначається як руйнування верхнього шару землі під впливом антропогенних та природних чинників. Це процес, який видаляє верхній шар поля разом з поживними речовинами, а, отже, згубно впливає на родючість господарських угідь.',
  },
  resultPredictingP2: {
    en: 'Much consideration has been given to the role of wind as an agent of geomorphological change on Earth and other planets. Aeolian processes involve wind eroding materials, such as exposed rock, and moving particles through the air to contact other materials and deposit them elsewhere. of these forces are notably similar to models in fluvial environments.',
    uk: 'Однією з причин ерозії є клімат, адже велика кількість рясних опадів може напряму впливати на деградацію ґрунтів. У результаті проливних дощів райони зі слабкою рослинністю та корінням особливо вразливі для дощових потоків, тому тут виснаження полів буде неминучим.',
  },
  resultPredictingP3: {
    en: 'Aeolian processes demonstrate their most notable consequences in arid regions of sparse and abundant unconsolidated sediments, such as sand.',
    uk: 'Вітрова ерозія відбувається через пориви вітру; в основному вітер являє собою серйозну загрозу, оскільки поле сухе й, отже, є чутливим до змін.',
  },
  resultDeterminationTitle: {
    en: 'Determination of chemical parameters',
    uk: 'Визначення хімічних показників'
  },
  resultDeterminationP1: {
    en: "Using thermal imaging to find dry spots in need of irrigation or to help calculate the vegetation index are other uses that come to mind. We recently wrote about the numerous ways drones are helping advance environmental initiatives.",
    uk: "Всі показники санітарного стану грунту можна розділити на прямі і непрямі (непрямі). Прямі показники дають можливістьбезпосередньо за результатами лабораторного дослідження грунту оцінити рівень її забруднення і ступінь небезпеки для здоров'я населення.",
  },
  resultDeterminationP2: {
    en: 'Using much of the same functionality that drones in: agriculture, disease detection/control, and weather forecasting, drones can also be used to help with conversation efforts. A few examples are: the monitoring & tracking of animals, anti-poaching efforts, and the ability to collect samples. Drones can be used to research on ecosystems and the life therein.',
    uk: 'За непрямими показниками можна зробити висновки про факт існування забруднення, його давності і тривалості шляхомпорівняння результатів лабораторного аналізу досліджуваної грунту з чистою контрольної грунтом того ж типу (що має однаковий природний склад з досвідченою), відібраної з незабруднених територій.',
  },
  resultFiresTitle: {
    en: 'Analysis of landscape fires',
    uk: 'Аналіз ландшафтних пожеж'
  },
  resultFiresP1: {
    en: 'In general search and rescue, medical, and healthcare applications, drones could add vital functionality to humanitarian and disaster relief efforts. Immediate assessment of damage and information regarding the best way to access disaster areas could cut response time and allow more efficient deployment of resources.',
    uk: 'Використання безпілотників дозволяє за короткий час забезпечити підприємства лісового господарства актуальною просторовою інформацією. Використовуйте економічно обгрунтований спосіб моніторингу лісових земель.',
  },
  resultFiresP2: {
    en: 'Some of the best operating deployments for drones are in large open areas, making agriculture a seeming ideal use case.',
    uk: 'Повітряний моніторинг земельних ресурсів із застосуванням безпілотників надає фахівцям унікальну можливість відстежувати зміни земельного фонду. Зображення, отримані з дрона, дозволяють оцінювати, прогнозувати  й запобігати виникненню можливих надзвичайних ситуацій.',
  },
  resultFiresP3: {
    en: 'Using drones to: gather data and perform analysis (3-D maps, soil analysis, irrigation mapping, and soil composition analysis), cover large distances, provide aerial views, perform manual redundant tasks (seeding, planting, and spraying), and allow farmers to monitor their entire enterprise from one location are some of the use cases for drones in agriculture.',
    uk: 'Здійснення моніторингу  лісових ресурсів, в пожежонебезпечний сезон дає можливість виявити вогнища загоряння на ранніх стадіях, провести екологічний контроль, виявити несанкціоновану діяльність на території заповідників та історико-культурних пам’яток природи.',
  },
  resultReliefTitle: {
    en: '3D relief model',
    uk: '3D модель рельєфу'
  },
  resultReliefP1: {
    en: 'In general search and rescue, medical, and healthcare applications, drones could add vital functionality to humanitarian and disaster relief efforts. Immediate assessment of damage and information regarding the best way to access disaster areas could cut response time and allow more efficient deployment of resources.',
    uk: 'Використання безпілотників дозволяє за короткий час забезпечити підприємства лісового господарства актуальною просторовою інформацією. Використовуйте економічно обгрунтований спосіб моніторингу лісових земель.',
  },
  resultReliefP2: {
    en: 'Some of the best operating deployments for drones are in large open areas, making agriculture a seeming ideal use case.',
    uk: 'Повітряний моніторинг земельних ресурсів із застосуванням безпілотників надає фахівцям унікальну можливість відстежувати зміни земельного фонду. Зображення, отримані з дрона, дозволяють оцінювати, прогнозувати  й запобігати виникненню можливих надзвичайних ситуацій.',
  },
  resultReliefP3: {
    en: 'Using drones to: gather data and perform analysis (3-D maps, soil analysis, irrigation mapping, and soil composition analysis), cover large distances, provide aerial views, perform manual redundant tasks (seeding, planting, and spraying), and allow farmers to monitor their entire enterprise from one location are some of the use cases for drones in agriculture.',
    uk: 'Здійснення моніторингу  лісових ресурсів, в пожежонебезпечний сезон дає можливість виявити вогнища загоряння на ранніх стадіях, провести екологічний контроль, виявити несанкціоновану діяльність на території заповідників та історико-культурних пам’яток природи.',
  },
  resultRailwaysTitle: {
    en: 'Railway monitoring',
    uk: 'Моніторинг залізної дороги'
  },
  resultRailwaysP1: {
    en: 'Drones in the Railway industry have become a piece of necessary equipment for stable railway inspection systems. They are employed to monitor significant railway infrastructures such as high-voltage electrical lines, railway catenary lines, and even tracks and switching points.',
    uk: 'Безпілотники записують зображення на інфраструктурних об’єктах і відправляють їх в режимі реального часу на місце дислокації оперативної команди воєнізованої охорониПАТ "Укрзалізниця". ',
  },
  resultRailwaysP2: {
    en: 'With drone technology\'s assistance, extensive drone imagery is obtained, to magnify the process of defect detections, crack detections, and additional dangers quickly.',
    uk: 'Завдяки тому, що встановлені на них камери мають оптичний зум високої якості, виникає можливість отримати візуальні докази негативної активності, що дозволяють виявляти злочинців. Дрони практично не чутні в повітрі, більш того, їх важко виявити через невеликі розміри.',
  },
  resultRailwaysP3: {
    en: 'As the complexity and availability of drones has increased so has the capabilities of cameras and other equipment used by. In addition to the ability to get aerial footage, drones can be mounted with infrared capabilities making victims easier to find. Drones could explore hard to reach areas after natural disasters and may make the exploration of confined spaces easier. Using drones during search and rescue may allow for a better deployment of human resources during the search',
    uk: 'Вони можуть використовуватися також вночі і в несприятливих погодних умовах, оскільки оснащені високоякісними камерами тепловізорної дії, що дозволяють виявити підозрілі об’єкти в будь-який час доби, на відстані більше одного кілометру. Разом з тим, безпілотники можна залучити до виявленькрадіжок дизельного пального з локомотивів.',
  },
}

export default orderCompleted
