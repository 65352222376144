import React, { useEffect, useRef, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import classNames from 'classnames'
import Button from '@components/button'

import styles from './index.module.scss'

const popupRoot = document.getElementById('popup-root')

export enum CONFIRM_BUTTON_TYPE {
  DANGER = 'danger',
  PRIMARY = 'primary',
}

export type PopupButton = {
  type: CONFIRM_BUTTON_TYPE
  text: string
  onClick: () => void
}

type PopupProps = {
  title: string
  confirmButton: PopupButton
  subtitle?: string | ReactElement
  cancelBtnText?: string
  onClose?: () => void
}

export const Popup: React.FC<PopupProps> = ({
  title,
  subtitle,
  onClose,
  confirmButton,
  cancelBtnText,
}) => {
  const popupRef = useRef(document.createElement('div'))

  useEffect(() => {
    popupRoot?.appendChild(popupRef.current)

    return () => {
      popupRoot?.removeChild(popupRef.current)
    }
  }, [])

  return createPortal(
    <div className={styles.modalWrap}>
      <div className={styles.modalContainer}>
        <div className={styles.body}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.subtitle}>{subtitle}</p>
        </div>
        <span className={styles.btnHorizontalDivider}></span>
        <div className={styles.modalBtnContainer}>
          {onClose ? (
            <>
              <Button
                className={styles.btn}
                variant="text"
                onClick={onClose}
              >
                {cancelBtnText}
              </Button>
              <span className={styles.btnVerticalDivider}></span>
            </>
          ) : null}
          <Button
            className={classNames(styles.btn, {
              [styles.isBtnDanger]: confirmButton.type === CONFIRM_BUTTON_TYPE.DANGER
            })}
            variant="text"
            onClick={confirmButton.onClick}
          >
            {confirmButton.text}
          </Button>
        </div>
      </div>
    </div>,
    popupRef.current,
  )
}
