import classnames from 'classnames'
import translate from '@tools/translation'
import { OrderCategory } from '@domain/order-category'
import { Link } from '@components/link'

import styles from './index.module.scss'

type CategoryStepProps = {
  categories: OrderCategory[]
  onSelectCategory: (category: OrderCategory) => void
}

export const CategoryStep = (props: CategoryStepProps) => {
  const translations: StringTranslations = translate.use().categories

  return (
    <div className={styles.container}>
      {props.categories.map((category, index) => (
        <Link
          key={category.id}
          iconName={category.iconName}
          text={translations[category.name]}
          disabled={!category.available}
          onClick={() => props.onSelectCategory(category)}
          className={classnames({
            [styles.firstCategory]: index === 0,
            [styles.lastCategory]: index === props.categories.length - 1,
          })}
        />
      ))}
    </div>
  )
}
