import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { IOrderProcessController, OrderProcessControllerDeps } from './_types'

export const useOrderProcessController = (
  { useCases }: OrderProcessControllerDeps
): IOrderProcessController => {
  const { id: orderIdStr } = useParams()
  const orderId = Number.parseInt(orderIdStr ?? '0')

  useEffect(() => {
    useCases.loadOrder.execute(orderId)
      .then(() => useCases.subscribeOrderStatus.execute(orderId))
    return () => {
      useCases.subscribeOrderStatus.stop()
    }
  }, [])

  return {
    ...useCases
  }
}
