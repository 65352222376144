import { action, makeObservable, observable, computed } from 'mobx'
import { IMapService } from '@application/ports'
import { MapboxService } from '@tools/map/mapbox'

export type MapNotification = {
  title: string
  text: string
  icon: string
}

export const MAP_CONTAINER_ID = 'map-container'

export class MapUIState {
  @observable selectedArea: Point[] = []
  @observable private notifications: MapNotification[] = []
  private translations: StringTranslations | null = null
  private map: IMapService
  private notificationTimer: Timer | null = null

  constructor() {
    makeObservable(this)
    this.map = new MapboxService({
      containerId: MAP_CONTAINER_ID,
      onChangeArea: this.selectArea.bind(this),
      onOutOfLimitArea: this.notifyAboutOutOfLimitPolygon.bind(this)
    })
  }

  @computed get notification(): MapNotification | null {
    return this.notifications.length ? this.notifications[this.notifications.length - 1] : null
  }

  public init(selectedArea: Point[], translations: StringTranslations) {
    this.translations = translations
    this.map.init(selectedArea)
    this.selectArea(selectedArea)
    this.addNotification({
      title: translations.mapLimitedTitle,
      text: translations.mapLimitedText,
      icon: '☝'
    })
  }

  @action public addNotification(notification: MapNotification) {
    this.notifications.push(notification)
  }

  @action public removeLastNotification() {
    this.notifications.pop()
  }

  @action public selectArea(polygon: Point[]) {
      this.selectedArea = polygon
  }

  public onDestroy() {
    if (this.notificationTimer) {
      clearInterval(this.notificationTimer)
    }
  }

  private notifyAboutOutOfLimitPolygon() {
    this.addNotification({
      title: this.translations?.mapUnableTitle ?? '',
      text: this.translations?.mapUnableText ?? '',
      icon: '‼️'
    })
    this.notificationTimer = setTimeout(() => this.removeLastNotification(), 4000)
  }

}
