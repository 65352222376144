import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { IOrderResultsController, OrderResultsControllerDeps } from './_types'

export const useOrderResultsController = (
  { useCases }: OrderResultsControllerDeps
): IOrderResultsController => {
  const { id: orderIdStr } = useParams()
  const orderId = Number.parseInt(orderIdStr ?? '0')

  useEffect(() => {
    useCases.loadOrder.execute(+orderId)
  }, [])

  return {
    ...useCases
  }
}
