import classNames from 'classnames'
import { Checkbox, CheckboxProps } from '@components/checkbox'

import styles from './index.module.scss'

type Props = CheckboxProps & {
  text: string
  className?: string
}

export const ResearchingListItem = ({ className, text, ...props }: Props) => {
  return (
    <div className={classNames(className, styles.container)}>
      <h3>{text}</h3>
      <Checkbox className={styles.checkbox} {...props} />
    </div>
  )
}
