import { action, makeObservable, observable, computed } from 'mobx'
import { OrderCategory } from '@domain/order-category'
import { OrderPayload } from '@domain/order-payload'
import { CategoryResearching } from '@domain/category-researching'
import { ICreateOrderStore } from '@application/ports'
import { CREATE_ORDER_STEPS } from '@application/types'
import { NoSelectedCategoryError } from '@application/errors'

export class CreateOrderStore implements ICreateOrderStore {
  @observable categories: OrderCategory[] = []
  @observable step: CREATE_ORDER_STEPS = CREATE_ORDER_STEPS.CATEGORY
  @observable selectedResearchingList: CategoryResearching[] = []
  @observable selectedArea: Point[] = []
  @observable selectedTime: string = ''
  @observable private writeOnlySelectedCategory: OrderCategory | null = null

  constructor() {
    makeObservable(this)
  }

  @computed get selectedCategory(): OrderCategory {
    if (this.writeOnlySelectedCategory) return this.writeOnlySelectedCategory
    else throw new NoSelectedCategoryError()
  }

  @computed get availableResearchingList(): CategoryResearching[] {
    return this.selectedCategory.researchingList
  }

  @computed get isOrderCreatingAllowed(): boolean {
    return !!this.selectedTime &&
      !!this.selectedArea &&
      !!this.writeOnlySelectedCategory &&
      !!this.selectedResearchingList.length
  }

  @computed get orderPayload(): OrderPayload {
    return {
      time: this.selectedTime,
      area: this.selectedArea,
      category: this.selectedCategory,
      researchingList: this.selectedResearchingList
    }
  }

  @action public setCategories(categories: OrderCategory[]) {
    this.categories = categories
  }

  @action public setStep(step: CREATE_ORDER_STEPS) {
    this.step = step
  }

  @action public setSelectedCategory(selectedCategory: OrderCategory) {
    this.writeOnlySelectedCategory = selectedCategory
  }

  @action public setSelectedResearchingList(researchingList: CategoryResearching[]) {
    this.selectedResearchingList = researchingList
  }

  @action public setSelectedArea(area: Point[]) {
    this.selectedArea = area
  }

  @action public setSelectedTime(time: string) {
    this.selectedTime = time
  }

}
