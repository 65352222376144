import { Icon } from '@components/icon'

import styles from './index.module.scss'

type InfoCardProps = {
  title: string
  text: string
  icon: string
  onClose?: () => void
}

export const InfoCard = (props: InfoCardProps) => {
  return (
    <div className={styles.container}>
      <span role="img">
        {props.icon}
      </span>
      <div className={styles.text}>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
      </div>
      {props.onClose && (
        <button type="button" className={styles.close} onClick={props.onClose}>
          <Icon iconName="close" />
        </button>
      )}
    </div>
  )
}
