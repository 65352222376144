type CopyFunction<TFn, TR> = TFn extends (...a: infer A) => any ? (...a: A) => TR : string;
type Translations<TGroup> = {
  [group in keyof TGroup]: {
    [key in keyof TGroup[group]]: CopyFunction<TGroup[group][key], string>;
  };
};
type Options<TValue> = {
  language: keyof TValue;
  fallback: keyof TValue;
};
export type TranslationsObject<TGroup, TValue> = {
  translations: Translations<TGroup>;
  use: () => Translations<TGroup>;
  useOptions: () => Options<TValue>;
  setOptions: (options: Options<TValue>) => any;
  getOptions: () => Options<TValue>;
};
export type TranslationLanguages = {
  en: string
  uk: string
}
export type LangOptions = 'en' | 'uk'

export enum CREATE_ORDER_STEPS {
  CATEGORY = 'category',
  RESEARCH = 'research',
  MAP = 'map',
  REQUESTS = 'requests',
  TIME = 'time',
  PREVIEW = 'preview'
}

export enum RESEARCHING_TYPES {
  FIELD_MEASUREMENTS = 1,
  FIELD_ROADS,
  SOIL_EROSION,
  CHEMICAL_PARAMETERS,
  FIRES,
  RELIEF_RESULTS,
  RAILWAYS
}

export type MapData = {
  points: Point[],
  width: number,
  height: number
}
