import DrawPolygon from '@mapbox/mapbox-gl-draw/src/modes/draw_polygon'
import isEventAtCoordinates from '@mapbox/mapbox-gl-draw/src/lib/is_event_at_coordinates'
import * as Constants from '@mapbox/mapbox-gl-draw/src/constants'
import doubleClickZoom from '@mapbox/mapbox-gl-draw/src/lib/double_click_zoom'

const CustomDrawPolygon = Object.assign({}, DrawPolygon)

CustomDrawPolygon.clickAnywhere = function(state, e) {
  if (state.currentVertexPosition > 0 && isEventAtCoordinates(e, state.polygon.coordinates[0][state.currentVertexPosition - 1])) {
    return this.changeMode(Constants.modes.SIMPLE_SELECT, { featureIds: [state.polygon.id] })
  }
  this.updateUIClasses({ mouse: Constants.cursors.ADD })
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
  state.currentVertexPosition++
  state.polygon.updateCoordinate(`0.${state.currentVertexPosition}`, e.lngLat.lng, e.lngLat.lat)
  if (state.polygon.isValid()) {
    this.map.fire('polygon-vertex-added', {
      features: [state.polygon.toGeoJSON()]
    })
  }
}

CustomDrawPolygon.onStop = function(state) {
  this.updateUIClasses({ mouse: Constants.cursors.NONE })
  doubleClickZoom.enable(this)
  this.activateUIButton()

  // check to see if we've deleted this feature
  if (this.getFeature(state.polygon.id) === undefined) {
    this.map.fire('not-finished-polygon-deleted')
    return
  }

  //remove last added coordinate
  state.polygon.removeCoordinate(`0.${state.currentVertexPosition}`)
  if (state.polygon.isValid()) {
    this.map.fire(Constants.events.CREATE, {
      features: [state.polygon.toGeoJSON()]
    })
  } else {
    this.deleteFeature([state.polygon.id], { silent: true })
    this.changeMode(Constants.modes.SIMPLE_SELECT, {}, { silent: true })
  }
}




export default CustomDrawPolygon
