import { observer, useLocalObservable } from 'mobx-react-lite'
import { StorageFabric } from '@tools/storage'
import translate from '@tools/translation'
import { OrdersRepository } from '@repositories/orders'
import { appSettings } from '@repositories/app-settings'
import { useCreateInstance } from '@application/hooks/create-instance'

import { LoadOrdersUseCase } from '@application/use-cases/load-orders'
import { SetLocaleUseCase } from '@application/use-cases/set-locale'
import { GetLocaleUseCase } from '@application/use-cases/get-locale'

import { useOrdersController } from './_controller'
import { OrdersStore } from './_store'
import { OrdersListContainer } from './orders.component'

const View = observer(OrdersListContainer)

export const OrdersListPage = () => {
  const store = useLocalObservable(() => new OrdersStore())
  const repository = useCreateInstance(() => new OrdersRepository(StorageFabric.getLocalStorage()))
  const controller = useOrdersController({
    useCases: {
      loadOrders: useCreateInstance(() => new LoadOrdersUseCase(repository, store)),
      setLocale: useCreateInstance(() => new SetLocaleUseCase(appSettings, store, translate)),
      getLocale: useCreateInstance(() => new GetLocaleUseCase(appSettings, store))
    }
  })

  return <View controller={controller} store={store} />
}
