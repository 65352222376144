import { useRef, useState, useEffect } from 'react'
import { isIphoneSafari } from '@tools/platform'
import translate from '@tools/translation'

import styles from './index.module.scss'

type OrderProcessVideoProps = {
  videoUrl: string
}

export const OrderProcessVideo = ({ videoUrl }: OrderProcessVideoProps) => {
  const translations = translate.use().orderProcess
  const [isVideoFullscreen, setFullscreenStatus] = useState<boolean>(isIphoneSafari)
  const videoRef = useRef<HTMLVideoElement>(null)

  const openFullscreen = async () => {
    if (videoRef.current && videoRef.current.requestFullscreen) {
      await videoRef.current.requestFullscreen();
    } else if (videoRef.current && 'mozRequestFullScreen' in videoRef.current) {
      // @ts-ignore
      await videoRef.current.mozRequestFullScreen();
    } else if (videoRef.current && 'webkitRequestFullscreen' in videoRef.current) {
      // @ts-ignore
      await videoRef.current.webkitRequestFullscreen();
    }
  }

  const onFullScreen = () => {
    // @ts-ignore
    const isFullscreenNow = !!document.webkitFullscreenElement || !!document.mozFullscreenElement || !!document.fullscreenElement
    setFullscreenStatus(isFullscreenNow)
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.addEventListener('fullscreenchange', onFullScreen)
      videoRef.current.addEventListener('mozfullscreenchange', onFullScreen)
      videoRef.current.addEventListener('webkitfullscreenchange', onFullScreen)
      return () => {
        if (videoRef.current) {
          videoRef.current.removeEventListener('fullscreenchange', onFullScreen)
          videoRef.current.removeEventListener('mozfullscreenchange', onFullScreen)
          videoRef.current.removeEventListener('webkitfullscreenchange', onFullScreen)
        }
      }
    }
  }, [videoRef.current])

  return (
    <div className={styles.container}>
      <video
        autoPlay
        muted
        playsInline
        loop
        controls={isVideoFullscreen}
        ref={videoRef}
        className={styles.video}
        onClick={openFullscreen}
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className={styles.liveLabel}>
        <span className={styles.liveLabelPoint} />
        {translations.live}
      </div>
    </div>
  )
}
