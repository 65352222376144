import { ICategoriesRepository, ICreateOrderStore } from '../ports'

export interface ILoadCategoriesUseCase {
  execute: () => Promise<void>
}

export class LoadCategoriesUseCase implements ILoadCategoriesUseCase {
  private repository: ICategoriesRepository
  private store: ICreateOrderStore

  public constructor(repository: ICategoriesRepository, store: ICreateOrderStore) {
    this.repository = repository
    this.store = store
  }

  public async execute() {
    const categories = await this.repository.getAll()
    this.store.setCategories(categories)
  }
}
