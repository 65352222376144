const categories = {
  ['Landscape fires']: {
    en: 'Landscape fires',
    uk: 'Ландшафтні пожежі',
  },
  ['Land inventory']: {
    en: 'Land inventory',
    uk: 'Інвентаризація земель',
  },
  ['Digital terrain models']: {
    en: 'Digital terrain models',
    uk: 'Цифрові моделі рельефу',
  },
  ['Railway monitoring']: {
    en: 'Railways monitoring',
    uk: 'Моніторинг залізних доріг',
  },
  ['Emergencies']: {
    en: 'Emergencies',
    uk: 'Надзвичайні ситуаціі',
  },
  ['Geodesy and cartography']: {
    en: 'Geodesy and cartography',
    uk: 'Геодезія та картографія',
  },
  ['Mining']: {
    en: 'Mining',
    uk: 'Видобування корисних копалин',
  },
  ['Public order']: {
    en: 'Civil order',
    uk: 'Громадський порядок',
  },
  ['Border guard']: {
    en: 'Border control/border security',
    uk: 'Охорона кордона',
  },
  ['Agriculture']: {
    en: 'Agriculture',
    uk: 'Сільське господарство',
  },
  ['Environmental monitoring']: {
    en: 'Environmental monitoring',
    uk: 'Екологічний моніторінг',
  },
  ['Search and rescue operations']: {
    en: 'Search and rescue activities',
    uk: 'Пошуково рятувальні операції',
  },
  ['Construction']: {
    en: 'Construction',
    uk: 'Будівніцтво',
  },
  ['Energy']: {
    en: 'Energy industry',
    uk: 'Енергетика',
  },
  ['Field measurements']: {
    en: 'Field measurements',
    uk: 'Обміри полів',
  },
  ['Design of field roads']: {
    en: 'Field roads design ',
    uk: 'Проектування польових доріг ',
  },
  ['Predicting soil erosion']: {
    en: 'Soil erosion prediction',
    uk: 'Прогнозування ерозії грунтів',
  },
  ['Determination of chemical parameters']: {
    en: 'Determination of chemical parameters',
    uk: 'Визначення хімічних показників ',
  },
  ['Monitoring of landscape fires']: {
    en: 'Landscape fires monitoring',
    uk: 'Моніторинг ландшафтних пожеж',
  },
  ['3D relief model']: {
    en: '3D terrain model',
    uk: '3D модель рельєфу',
  },
}

export default categories
