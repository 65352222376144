import { CategoryResearching } from '@domain/category-researching'
import { IOrderResultsStore } from '@application/ports'

export interface IShowResearchingResultUseCase {
  execute: (researching: CategoryResearching) => void
  undo: () => void
}

export class ShowResearchingListUseCase implements IShowResearchingResultUseCase {
  private store: IOrderResultsStore

  public constructor(store: IOrderResultsStore) {
    this.store = store
  }

  public execute(researching: CategoryResearching): void {
    this.store.selectResearching(researching)
  }

  public undo(): void {
    this.store.closeResearching()
  }
}
