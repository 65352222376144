const ordersList = {
  title: {
    en: 'Orders',
    uk: 'Замовлення',
  },
  language: {
    en: 'Eng',
    uk: 'Укр',
  },
  text: {
    en: 'You have no orders',
    uk: 'У вас немає замовлень',
  },
  textBtn: {
    en: 'Create your first order',
    uk: 'Створіть перше замовлення',
  },
  process: {
    en: 'In progress',
    uk: 'в процесі',
  },
  done: {
    en: 'Complete',
    uk: 'готове',
  },
  to: {
    en: 'To',
    uk: 'До',
  },
  notificationOrderCreated: {
    en: 'The order has been created',
    uk: 'Замовлення створено',
  },
}

export default ordersList
