export class Entity<DTO extends { id: ID }> {

  protected data: DTO

  public constructor(data: DTO) {
    this.data = data
  }

  public get id(): ID {
    return this.data.id
  }

}
