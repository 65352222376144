const orderProcess = {
  titleTime: {
    en: 'Estimated completion time',
    uk: 'Приблизний час завершення',
  },
  notificationResult: {
    en: 'The flight is completed',
    uk: 'Політ завершено',
  },
  live: {
    en: 'Live',
    uk: 'Наживо',
  },
}

export default orderProcess
