import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

type Options = {
  name: string
  defaultValue: string
}

export const useSearchParam = (
  options: Options,
): [string, (newParam: string) => void] => {
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChange = useCallback(
    (newValue: string) => {
      searchParams.set(options.name, newValue)
      setSearchParams(searchParams)
    },
    [options.name, searchParams, setSearchParams],
  )

  return [searchParams.get(options.name) ?? options.defaultValue, handleChange]
}
