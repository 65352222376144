import React from 'react'
import ReactDOM from 'react-dom'
import 'virtual:svg-icons-register';

import App from './ui/app'
import './ui/_styles_/main.scss'


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
