import { useEffect } from 'react'

import { IOrdersController, OrdersControllerDeps } from './_types'

export const useOrdersController = ({ useCases }: OrdersControllerDeps): IOrdersController => {
  useEffect(() => {
    useCases.getLocale.execute()
    useCases.loadOrders.execute()
  }, [])

  return {
    ...useCases
  }
}
