import { ReactNode } from 'react'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { Icon } from '@components/icon'
import Button from '@components/button'

import { ResearchingResultDrawer } from './drawer'
import styles from './index.module.scss'

type ResearchResultsProps = {
  image: string
  children: ReactNode
  onClose: () => void
}

export const ResearchResults = (props: ResearchResultsProps) => {
  return (
    <div className={styles.container}>
      <Button className={styles.btn} onClick={props.onClose}>
        <Icon iconName="arrow-tailed-left" />
      </Button>

      <TransformWrapper centerOnInit initialScale={4} minScale={4}>
        <TransformComponent wrapperClass={styles.imageContainer} contentClass={styles.imageContent}>
          <img className={styles.image} src={props.image} />
        </TransformComponent>
      </TransformWrapper>

      <ResearchingResultDrawer>
        {props.children}
      </ResearchingResultDrawer>
    </div>
  )
}
