import { Decoder } from '@tools/decoder'
import { Storage } from './storage.interface'

export class LocalStorageService implements Storage {
    public save(key: string, data: unknown): void {
        localStorage.setItem(key, JSON.stringify(data));
    }

    public get<Entity>(key: string, decoder: Decoder<Entity>): Entity | null {
        const json = localStorage.getItem(key);
        const data = json ? JSON.parse(json) : null;
        if (!data) return null
        return decoder(data)
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }
}
