import { Fragment, ReactNode, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'


import { AppRoutes } from './routes'

function CssGlobals(props: { children: ReactNode }) {
  useEffect(() => {
    function setVh() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    setVh()

    window.addEventListener('resize', setVh)

    return () => {
      window.removeEventListener('resize', setVh)
    }
  }, [])

  return <Fragment>{props.children}</Fragment>
}

function App() {
  return (
    <BrowserRouter>
      <CssGlobals>
        <AppRoutes />
      </CssGlobals>
    </BrowserRouter>
  )
}

export default App
