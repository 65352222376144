import { IOrdersRepository, IOrderStore } from '../ports'

export interface ILoadOrderUseCase {
  execute: (id: number) => Promise<void>
}

export class LoadOrderUseCase implements ILoadOrderUseCase {
  private repository: IOrdersRepository
  private store: IOrderStore

  public constructor(repository: IOrdersRepository, store: IOrderStore) {
    this.repository = repository
    this.store = store
  }

  public async execute(id: number) {
    const order = await this.repository.getById(id)
    this.store.setOrder(order)
    this.store.finishLoading()
  }
}
