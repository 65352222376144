export type Decoder<T> = (value: unknown) => T

type ArrayDecoder = <T>(map: Decoder<T>) => Decoder<Array<T>>
export type ObjectFieldDecoder = <T>(fieldName: string, decode: Decoder<T>) => T
type ObjectDecoder = <T>(map: (fieldDecoder: ObjectFieldDecoder) => T) => (value: unknown) => T
type ObjectDecoderOrNull = <T>(map: (fieldDecoder: ObjectFieldDecoder) => T) => (value: unknown) => T | null

export class DecodeMethods {
    private static instance: DecodeMethods

    public static getInstance(): DecodeMethods {
        if (!DecodeMethods.instance) {
            DecodeMethods.instance = new DecodeMethods()
        }

        return DecodeMethods.instance
    }

    public string: Decoder<string> = value => {
        if (typeof value === 'string') {
            return value
        }

        throw new Error(`"${value}" is not a string.`)
    }

    public boolean: Decoder<boolean> = value => {
        if (typeof value === 'boolean') {
            return value
        }

        throw new Error(`"${value}" is not a boolean.`)
    }

    public number: Decoder<number> = value => {
        if (typeof value === 'string') {
            return Number.parseFloat(value)
        }

        if (typeof value === 'number') {
            return value
        }

        throw new Error(`"${value} is not a number"`)
    }

    public array: ArrayDecoder = decode => value => {
        if (Array.isArray(value)) {
            try {
                return value.map(item => decode(item))
            } catch (error) {
                throw new Error(`array has item that can not be decoded: ${error?.message}`)
            }
        }

        throw new Error('Array can not be decoded.')
    }

    public field = <T>(obj: Record<string, unknown>, name: string, decode: Decoder<T>): T => {
        return decode(obj[name])
    }

    public object: ObjectDecoder = decode => obj => {
        if (obj && typeof obj === 'object') {
            const decodeObjField = <T>(name: string, decode: Decoder<T>): T =>
                this.field(obj as Record<string, unknown>, name, decode)

            return decode(decodeObjField)
        }

        throw new Error('Value can not be decoded as object.')
    }

    public objectOrNull: ObjectDecoderOrNull = (decode) => (obj) => {
        if (typeof obj === 'object') {
            return obj ? this.object(decode)(obj) : null
        }

        throw new Error('Value can not be decoded as object or null.')
    }

    public date: Decoder<Date> = obj => {
        if (obj && obj instanceof Date) {
            return obj
        }
        throw new Error('Value can not be decoded.')
    }

    public optional = <T>(decode: Decoder<T>): Decoder<T | undefined> => {
        return value => {
            if (!value) {
                return undefined
            }

            return decode(value)
        }
    }

    public oneOf = <T extends string | number>(...args: T[]) => {
        return (value: unknown): typeof args[number] => {
            const findedValue = args.find(arg => arg === value)

            if (typeof findedValue === 'undefined') {
                throw new Error(`${value} is not of [${args.join(',')}]`)
            }

            return findedValue
        }
    }

    public type = <T>() => (value: unknown): T => {
        return value as T
    }
}
