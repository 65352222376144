import { action, makeObservable, observable, computed } from 'mobx'

export class TimeStepUIState {
  @observable time: string = ''
  @observable isDrawerOpened: boolean = false

  constructor(selectedTime: string) {
    makeObservable(this)
    this.time = selectedTime
  }

  @computed get isTimeSelected(): boolean {
    return !!this.time
  }

  @action selectTime(time: string) {
    this.time = time
    this.closeDrawer()
  }

  @action openDrawer() {
    this.isDrawerOpened = true
  }

  @action closeDrawer() {
    this.isDrawerOpened = false
  }
}
