import { observer, useLocalObservable } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { StorageFabric } from '@tools/storage'
import { OrdersRepository } from '@repositories/orders'
import { useCreateInstance } from '@application/hooks/create-instance'
import { SharingService } from '@application/services/sharing'

import { LoadOrderUseCase } from '@application/use-cases/load-order'
import { RepeatOrderUseCase } from '@application/use-cases/repeat-order'
import { ShareOrderUseCase } from '@application/use-cases/share-order'
import { ShowResearchingListUseCase } from '@application/use-cases/show-researching-result'

import { useOrderResultsController } from './_controller'
import { OrderResultsStore } from './_store'
import { OrderResults } from './order-results.component'

const View = observer(OrderResults)

export const OrderResultsPage = () => {
  const navigate = useNavigate()
  const store = useLocalObservable(() => new OrderResultsStore())
  const repository = useCreateInstance(() => new OrdersRepository(StorageFabric.getLocalStorage()))
  const controller = useOrderResultsController({
    useCases: {
      loadOrder: useCreateInstance(() => new LoadOrderUseCase(repository, store)),
      shareOrder: useCreateInstance(() => new ShareOrderUseCase(new SharingService())),
      repeatOrder: useCreateInstance(() => new RepeatOrderUseCase(store, { navigate })),
      showResearchingResult: useCreateInstance(() => new ShowResearchingListUseCase(store)),
    }
  })

  return <View controller={controller} store={store} />
}
