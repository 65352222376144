import translate from '@tools/translation'
import { formatDate } from '@tools/date'
import { Icon } from '@components/icon'
import { Drawer } from '@components/drawer'

import styles from './index.module.scss'

export type TimeDrawerProps = {
  isOpened: boolean
  onSelectTime: (time: string) => void
  times: string[]
  selectedTime: string
  onClose: () => void
}

export const TimeDrawer = (props: TimeDrawerProps) => {
  const translations = translate.use().createOrder

  return (
    <Drawer isOpened={props.isOpened} isAutoHeight contentClassName={styles.drawer}>
      <div className={styles.title}>
        <Icon iconName="close" onClick={props.onClose} />
        <h3>{translations.changeTime}</h3>
      </div>
      <div className={styles.timeWrap}>
        {props.times.map((time) => (
          <div key={time} className={styles.time} onClick={() => props.onSelectTime(time)}>
            {formatDate(time)}
            {time === props.selectedTime && <Icon className={styles.timeIcon} iconName="check" />}
          </div>
        ))}
      </div>
    </Drawer>
  )
}
