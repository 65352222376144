import { useEffect, useState } from "react";
import { observer, useLocalObservable } from 'mobx-react-lite'
import translate from '@tools/translation'
import { formatDate, getCurrentDate, getCurrentMonth, getCurrentYear } from '@tools/date'
import { Icon } from '@components/icon'
import Button from '@components/button'

import { TimeStepUIState } from './_ui-state'
import { TimeDrawer } from './time-drawer'
import styles from './index.module.scss'

type TimeStepProps = {
  selectedTime: string
  onSelectTime: (time: string) => void
}

export const TimeStep = observer((props: TimeStepProps) => {
  const TIMES = useState(() => [
    new Date(getCurrentYear(), getCurrentMonth(), getCurrentDate(), 17, 0).toISOString(),
    new Date(getCurrentYear(), getCurrentMonth(), getCurrentDate() + 1, 14, 0).toISOString(),
    new Date(getCurrentYear(), getCurrentMonth(), getCurrentDate() + 2, 18, 0).toISOString(),
    new Date(getCurrentYear(), getCurrentMonth(), getCurrentDate() + 3, 15, 0).toISOString(),
    new Date(getCurrentYear(), getCurrentMonth(), getCurrentDate() + 4, 16, 0).toISOString(),
  ])[0]
  const translations = translate.use().createOrder
  const uiState = useLocalObservable(() => new TimeStepUIState(props.selectedTime))

  useEffect(() => {
    uiState.selectTime(TIMES[0])
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.itemWrap}>
        <div className={styles.item}>
          {uiState.time && (
            <div className={styles.itemText}>
              <Icon iconName="plane" />
              <h3>{formatDate(uiState.time)}</h3>
            </div>
          )}
          <Button
            className={styles.itemBtn}
            variant="text"
            onClick={uiState.openDrawer.bind(uiState)}
          >
            {translations.changeBtn}
          </Button>
        </div>
        <p>{translations.avalibleTime}</p>
      </div>
      <Button
        disabled={!uiState.isTimeSelected}
        variant="primary"
        onClick={() => props.onSelectTime(uiState.time)}
      >
        {translations.nextBtn}
      </Button>
      <TimeDrawer
        isOpened={uiState.isDrawerOpened}
        times={TIMES}
        selectedTime={uiState.time}
        onSelectTime={uiState.selectTime.bind(uiState)}
        onClose={uiState.closeDrawer.bind(uiState)}
      />
    </div>
  )
})
