import classNames from 'classnames'
import { Icon } from '@components/icon'

import styles from './index.module.scss'

type InfoDetailsProps = {
  title: string
  text: string
  iconName: string
  className?: string
}

export const InfoDetails = ({ className, iconName, title, text }: InfoDetailsProps) => {
  return (
    <div className={classNames(className, styles.container)}>
      <Icon iconName={iconName} />
      <div className={styles.text}>
        <h4>{title}</h4>
        <h3>{text}</h3>
      </div>
    </div>
  )
}
