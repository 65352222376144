import { Entity } from './_entity'
import { CategoryResearching, CategoryResearchingDTO } from './category-researching'

export type OrderCategoryDTO = {
  id: ID
  name: string
  available: boolean
  researchingList?: CategoryResearchingDTO[]
}

export class OrderCategory extends Entity<OrderCategoryDTO> {
  private readonly researching: CategoryResearching[] = []

  public constructor(data: OrderCategoryDTO) {
    super(data)
    this.researching = data.researchingList?.map((item) => new CategoryResearching(item)) ?? []
  }

  public get name(): string {
    return this.data.name
  }

  public get available(): boolean {
    return this.data.available
  }

  public get researchingList(): CategoryResearching[] {
    return this.researching
  }

  public get iconName(): string {
    switch (this.id) {
      case 1: return 'fire'
      case 2: return 'leaf'
      case 3: return 'planet'
      case 4: return 'train'
      case 5: return 'danger'
      case 6: return 'compass'
      case 7: return 'hexagon'
      case 8: return 'shield'
      case 9: return 'lock'
      case 10: return 'tree'
      case 11: return 'drop'
      case 12: return 'horn'
      case 13: return 'house'
      case 14: return 'flash'
      default: return 'planet'
    }
  }

}
