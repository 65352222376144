import { Map, Marker, MapboxOptions } from 'mapbox-gl'
import MapboxDraw from '@mapbox/mapbox-gl-draw/index'
import { mapConfig } from '@application/configs'
import { IMapService } from '@application/ports'

import { convertBoundingBoxToPolygon, getBoundingBox, isEveryPointInCircle } from './helpers'
import { GeoMapboxService } from './geo-mapbox'
import CustomDrawPolygon from './custom-draw-mode'

const MAP_PRIMARY_COLOR = '#2f82c4'
const polygonStyles = [
  {
    "id": "gl-draw-polygon-fill",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    "paint": {
      "fill-color": "transparent",
      "fill-outline-color": "transparent",
    }
  },
  {
    "id": "gl-draw-polygon-fill-static",
    "type": "fill",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "simple_select"]],
    "paint": {
      "fill-color": MAP_PRIMARY_COLOR,
      "fill-outline-color": MAP_PRIMARY_COLOR,
      "fill-opacity": 0.1
    }
  },
  {
    "id": "gl-draw-line",
    "type": "line",
    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": MAP_PRIMARY_COLOR,
      "line-dasharray": [0.2, 2],
      "line-width": 3
    }
  },
  {
    "id": "gl-draw-polygon-stroke-active",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": MAP_PRIMARY_COLOR,
      "line-dasharray": [0.2, 2],
      "line-width": 3
    }
  },
  {
    "id": "gl-draw-polygon-stroke-static",
    "type": "line",
    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "simple_select"]],
    "layout": {
      "line-cap": "round",
      "line-join": "round"
    },
    "paint": {
      "line-color": MAP_PRIMARY_COLOR,
      "line-width": 3
    }
  },
  {
    "id": "gl-draw-polygon-and-line-vertex-halo-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 5,
      "circle-color": "#FFF"
    }
  },
  {
    "id": "gl-draw-polygon-and-line-vertex-active",
    "type": "circle",
    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    "paint": {
      "circle-radius": 3,
      "circle-color": MAP_PRIMARY_COLOR,
    }
  },
]

export type MapServiceOptions = {
  containerId: string
  onChangeArea: (selectedArea: Point[]) => void
  onOutOfLimitArea: () => void
}

export class MapboxService implements IMapService {
  private map: Map | null = null
  private marker: Marker | null = null
  private draw: MapboxDraw | null = null
  private geo: GeoMapboxService
  private readonly mapOptions: MapboxOptions
  private readonly bbox: [number, number, number, number]
  private onChangeArea: (selectedArea: Point[]) => void
  private onOutOfLimitArea: () => void

  constructor({ containerId, onChangeArea, onOutOfLimitArea }: MapServiceOptions) {
    this.onChangeArea = onChangeArea
    this.onOutOfLimitArea = onOutOfLimitArea
    this.geo = new GeoMapboxService()
    this.bbox = getBoundingBox(mapConfig.center[0], mapConfig.center[1], mapConfig.availableRadius + 40)
    this.mapOptions =  {
      container: containerId,
      style: mapConfig.styleURL,
      center: mapConfig.center,
      accessToken: mapConfig.token,
      maxBounds: this.bbox,
    }
  }

  public init(selectedArea: Point[]) {
    this.map = new Map(this.mapOptions)
    this.map.on('load', this.addLimitLayer.bind(this, this.map))
    this.addMarker(this.map)
    this.initDrawTools(this.map, selectedArea)
  }

  private updateArea() {
    const features = this.draw ? this.draw.getAll().features : []
    if (features.length > 0 && this.geo.isGeometryPolygon(features[0].geometry)) {
      const polygon = this.geo.removeDuplicatePolygonVertexes(features[0].geometry.coordinates[0])

      // This checking is necessary for custom draw mode, because it fires new custom event without validating polygon
      if (this.geo.isValidPolygon(polygon)) {
        if (isEveryPointInCircle(polygon, { center: mapConfig.center, R: mapConfig.availableRadius })) {
          return this.onChangeArea(polygon)
        }
        this.onOutOfLimitArea()
      }
    } else {
      this.onChangeArea([])
    }
  }

  private initDrawTools(map: Map, selectedArea: Point[]) {
    this.draw = new MapboxDraw({
      // @ts-ignore
      modes: Object.assign(MapboxDraw.modes, {
        draw_polygon: CustomDrawPolygon
      }),
      defaultMode: selectedArea.length ? 'simple_select' : 'draw_polygon',
      styles: polygonStyles,
      displayControlsDefault: false,
      controls: {
        polygon: true,
        trash: true
      }
    })
    map.addControl(this.draw)

    if (selectedArea.length) {
      this.draw.add(this.geo.createPolygonFeature(selectedArea))
    }

    map.on('draw.create', this.updateArea.bind(this))
    map.on('draw.update', this.updateArea.bind(this))
    map.on('draw.delete', this.updateArea.bind(this))

    // Custom events fired
    map.on('polygon-vertex-added', this.updateArea.bind(this))
    map.on('not-finished-polygon-deleted', this.updateArea.bind(this))
  }

  private addMarker(map: Map) {
    this.marker = new Marker({
      color: MAP_PRIMARY_COLOR
    })
      .setLngLat(mapConfig.center)
      .addTo(map)
  }

  private addLimitLayer(map: Map) {
    map.addSource('polygon', this.geo.createPolygonSource(convertBoundingBoxToPolygon(this.bbox)))
    map.addLayer({
      id: 'polygon',
      type: 'fill',
      source: 'polygon',
      paint: {
        "fill-color": 'black',
        "fill-opacity": 0.1
      }
    })

    map.addSource('circle', this.geo.createCircleSource(mapConfig.center, mapConfig.availableRadius))
    map.addLayer({
      id: 'circle',
      type: 'fill',
      source: 'circle',
      paint: {
        "fill-color": 'white',
        "fill-opacity": 0.25
      }
    })
  }

}
