import classNames from 'classnames'

import styles from './index.module.scss'

type ButtonProps = React.ComponentProps<'button'> & {
  variant?: 'primary' | 'text' | 'round' | 'secondary'
}

function Button({
  children,
  variant,
  className,
  ...props
}: ButtonProps) {
  return (
    <button
      type="button"
      className={classNames(className, styles.block, {
        [styles.primary]: variant === 'primary',
        [styles.text]: variant === 'text',
        [styles.round]: variant === 'round',
        [styles.secondary]: variant === 'secondary',
      })}
      {...props}
    >
      <span className={styles.label}>{children}</span>
    </button>
  )
}

export default Button
