import { decoder } from '@tools/http'
import { CategoryResearchingDTO } from "@domain/category-researching";

export const orderCategoryDecoder = decoder.object((field) => ({
  id: field('id', decoder.number),
  name: field('name', decoder.string),
  available: field('available', decoder.boolean),
  researchingList: field('researchingList',
    decoder.optional(
      decoder.array(
        decoder.object<CategoryResearchingDTO>((field) => ({
          id: field('id', decoder.number),
          name: field('name', decoder.string),
        }))
      )
    )
  )
}))

export const orderCategoriesDecoder = decoder.array(orderCategoryDecoder)
